import React from "react";
import { Page, Text, View, Document, PDFViewer, Image, Font } from "@react-pdf/renderer";
import { pdfStyle } from "./pdfStyles";
import { chunkArray } from "./OsloComGarantia";
import HyphenationCallback from "./PdfHyphenation";
import { parseDateHyphen, estadoPorExtenso } from "../Tools";
Font.registerHyphenationCallback(HyphenationCallback);

export const formatDateString = (inputDate) => {
    const months = [
        "janeiro", "fevereiro", "março", "abril", "maio", "junho",
        "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"
    ];

    const year = inputDate.getFullYear();
    const month = months[inputDate.getMonth()];
    const day = inputDate.getDate();

    return `${day} de ${month} de ${year}`;
};

const formatCnpj = (text) => {
    if (text) {
        const cnpj = text.replace(/\D/g, '');
        let formattedCnpj = '';

        for (let i = 0; i < cnpj.length; i++) {
            if (i === 2 || i === 5) {
                formattedCnpj += '.';
            } else if (i === 8) {
                formattedCnpj += '/';
            } else if (i === 12) {
                formattedCnpj += '-';
            }
            formattedCnpj += cnpj[i];
        }

        return formattedCnpj;
    }
};
function parseDateString(str) {
    const meses = [
        "janeiro", "fevereiro", "março", "abril", "maio", "junho",
        "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"
    ];

    const partes = str.split(' ');

    if (partes.length !== 5) {
        throw new Error("Formato de data inválido");
    }

    const dia = parseInt(partes[0], 10);
    const mes = meses.indexOf(partes[2]) + 1; // +1 porque o índice do array começa em 0
    const ano = parseInt(partes[4], 10);

    if (isNaN(dia) || isNaN(mes) || isNaN(ano)) {
        throw new Error("Formato de data inválido");
    }

    return new Date(ano, mes - 1, dia); // Mês é base 0, subtrai 1
}
function parseDate(str) {
    const parts = str.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);
    return new Date(year, month - 1, day); // Mês é base 0, subtrai 1
}
const OsloSemGarantia = ({ dados }) => {

    const formValues = JSON.parse(localStorage.getItem('formValues'));
    const numero = require('numero-por-extenso');
    const emissor = JSON.parse(localStorage.getItem('emissor'));
    const investidor = JSON.parse(localStorage.getItem('investidor'));
    const allInfoArray = JSON.parse(localStorage.getItem('allInfoArray'));
    const sacData = JSON.parse(localStorage.getItem('sacData'));
    const calcData2 = JSON.parse(localStorage.getItem('calcData'));
    const modelo = localStorage.getItem('modelo');
    const garantiaDataString = localStorage.getItem('garantiaDataArray');
    const garantiaData = JSON.parse(garantiaDataString);
    let calcData = modelo == 'sac' ? sacData : calcData2
    const now = formValues.dataEmissao ? parseDateHyphen(formValues.dataEmissao) : new Date();
    const parcelas = allInfoArray[4]
    const linhaDoVencimento = calcData ? calcData[calcData.length - 1] : sacData[sacData.length - 1]
    const linhaPrimeira = calcData ? calcData[0] : sacData[0]
    const dataPrimeira = linhaPrimeira[1] < 10 ? `0${linhaPrimeira[1]}/${linhaPrimeira[2] - 1}/${linhaPrimeira[3]}` : `${linhaPrimeira[1]}/${linhaPrimeira[2] - 1}/${linhaPrimeira[3]}`;
    const dataEmissao = formatDateString(now);
    const dataDoVencimento = linhaDoVencimento[1] < 10 ? `0${linhaDoVencimento[1]}/${linhaDoVencimento[2]}/${linhaDoVencimento[3]}` : `${linhaDoVencimento[1]}/${linhaDoVencimento[2]}/${linhaDoVencimento[3]}`;
    const data1 = parseDateString(dataEmissao);
    const data2 = parseDate(dataDoVencimento);
    const diffEmMilissegundos = data2 - data1;
    const umDiaEmMilissegundos = 1000 * 60 * 60 * 24;
    const totalDias = Math.floor(diffEmMilissegundos / umDiaEmMilissegundos);
    const enderecoDoEmissor = `${emissor.rua}, Nº ${emissor.numero}, ${emissor.complemento ? emissor.complemento + ", " : ""} - ${emissor.bairro} - ${emissor.cidade}/${emissor.estado}, CEP:${emissor.cep}`;
    const enderecoDoInvestidor = `${investidor.rua}, Nº ${investidor.numero}, ${investidor.complemento ? investidor.complemento + ", " : ""} - ${investidor.bairro} - ${investidor.cidade}/${investidor.estado}, CEP:${investidor.cep}`;
    const styles = pdfStyle
    const avalistas = emissor.avalistas || [];
    const fiel = emissor.fielDepositario || [];
    // console.log(fiel, 'fiel')
    const chunkedCalcData = chunkArray(calcData, 9); //aqui tem que que modelo foi
    let isFirstPage = true;

    function transformarInvestidor(investidor) {
        if (investidor.nome && investidor.nome2 && investidor.email) {
            const investidorTransformado = {
                ...investidor,
                representantes: [
                    { nome: investidor.nome, email: investidor.email }
                ]
            };
            // Se 'nome2' existir, adicione um segundo representante
            if (investidor.nome2) {
                investidorTransformado.representantes.push({
                    nome: investidor.nome2,
                    email: investidor.email
                });
            }
            return investidorTransformado;
        }

        return investidor; // Retorna o objeto original se não houver transformação
    }

    function getPreposition(local) {
        const words = local.split(' ');
        if (words[0].toLowerCase().endsWith('a')) {
            return 'na';
        } else {
            return 'no';
        }
    }

    const valorC2 = parseFloat(formValues.taxaEmissao) + parseFloat(formValues.alienacao) + parseFloat(formValues.tac)
    const valorC3 = parseFloat(formValues.taxaEmissao) + parseFloat(formValues.assinaturaDigital) + parseFloat(formValues.tac) + parseFloat(formValues.alienacao)
    const investidorTransformado = transformarInvestidor(investidor);
    const veiculo = garantiaData?.some(data => data?.gTipo === "Veículo");
    const imovel = garantiaData?.some(data => data?.gTipo === "Imóvel");

    return (
        <PDFViewer style={{ width: '90%', height: '90%' }}>
            <Document>
                <Page style={styles.page}>
                    <View style={styles.section}>
                        <Text style={[styles.textCenter]}>
                            TERMO DE EMISSÃO DA {dados.emissao} EMISSÃO DE NOTAS COMERCIAIS, EM SÉRIE ÚNICA, PARA DISTRIBUIÇÃO PRIVADA COM ESFORÇOS RESTRITOS DA <Text style={styles.textBold}>{emissor.razaoSocial}</Text></Text>
                        <View style={styles.header}>
                            <Text style={[styles.text1, { marginVertical: 80 }]}>entre</Text>
                        </View>
                        <View style={styles.header}>
                            <Text style={[styles.text1, { marginVertical: 20 }]}>{emissor.razaoSocial}{"\n"} como emitente</Text>
                        </View>
                        <View style={styles.header}>
                            <Text style={[styles.text1, { marginVertical: 20 }]}>OSLO CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A{"\n"}
                                como Escriturador
                            </Text>
                        </View>
                        <View style={[styles.header, { marginBottom: '190px' }]}>
                            <Text style={[styles.text1, { marginVertical: 20 }]}>{investidor.razaoSocial}{"\n"}
                                como Titular</Text>
                        </View>
                    </View>
                </Page>
                {/*Page Única*/}
                <Page style={styles.page}>
                    <View style={styles.section}>
                        <Text style={[styles.textBold, { textAlign: 'center', fontSize: 12, marginVertical: 18 }]}>NOTA COMERCIAL</Text>
                        <Text wrap style={[styles.text1, { textAlign: 'justify', marginHorizontal: 20, fontSize: 12 }]}>
                            <Text style={styles.textBold}>{emissor.razaoSocial}</Text> – Empresa de responsabilidade limitada, sem registro de capital aberto na Comissão de Valores Mobiliários (“CVM”), com sede na {enderecoDoEmissor}, inscrita no Cadastro Nacional da Pessoa Jurídica do Ministério da Economia (“CNPJ/ME”) sob o nº {emissor.cnpj}, com endereço eletrônico: {emissor.email}, neste ato representada por seus representantes legais {emissor.representante}, na qualidade de Emitente das Notas Comerciais (conforme definido abaixo);
                        </Text>
                        <Text style={[styles.text1, { textAlign: 'justify', marginHorizontal: 20, fontSize: 12, marginTop: 20 }]}>
                            <Text style={styles.textBold}>OSLO CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.</Text>, Com sede na Rua Doutor Eduardo de Souza Aranha nº 153, 4º andar, na cidade de São Paulo, estado de São Paulo, inscrita no Cadastro Nacional de Pessoas Jurídicas do Ministério da Fazenda ("CNPJ/MF") sob o nº 13.673.855/0001-25, neste ato representada na forma de seu estatuto social, doravante denominada simplesmente ESCRITURADORA
                            ;
                        </Text>
                        <Text style={[styles.text1, { textAlign: 'justify', marginHorizontal: '20px', fontSize: 12, marginTop: '20px' }]}>
                            <Text style={styles.textBold}>{investidor.razaoSocial}</Text> – Empresa de responsabilidade limitada, sem registro de capital aberto na Comissão de Valores Mobiliários (“CVM”), com sede na {enderecoDoInvestidor}, inscrita no Cadastro Nacional da Pessoa Jurídica do Ministério da Economia (“CNPJ/ME”) sob o nº {investidor.cnpj}, com endereço eletrônico: {investidor.representantes[0].email}, neste ato representada por seus representantes legais {investidor.representantes.map((item, index) => (
                                <React.Fragment key={index}>
                                    {item.nome}
                                    {index < investidor.representantes.length - 1 && '\n'}
                                </React.Fragment>
                            ))}
                            , na qualidade de Titular das Notas Comerciais (conforme definido abaixo);
                        </Text>
                        {avalistas.map((avalista, index) => (
                            <Text key={index} style={[styles.text1, { textAlign: 'justify', marginHorizontal: '20px', fontSize: 12, marginTop: '20px' }]}>
                                <Text style={styles.textBold}>AVALISTA</Text>: {avalista.aNome}, {avalista?.Nacionalidade ? avalista.aNacionalidade + ", " : ""}{avalista.aProfissao ? avalista.aProfissao + ", " : ""}{avalista.aEstadoCivil ? avalista.aEstadoCivil + ", " : ""} portador do documento de identidade RG n º {avalista.aRg} e inscrito no CPF/MF sob o n º {avalista.aCpf}, residente e domiciliado {getPreposition(avalista.aRua)} {avalista.aRua}, n º {avalista.aNumero}, {avalista.aComplemento ? avalista.aComplemento + ',' : ''} {avalista.aBairro} {avalista.aCidade}/{avalista.aEstado} CEP {avalista.aCep} e, na forma de seus atos constitutivos, email: {avalista.aEmail}; Na qualidade de Avalista
                            </Text>
                        ))}
                        {garantiaData && garantiaData?.length > 0 && garantiaData[0]?.gTipo && fiel?.nome && (
                            <Text style={[styles.text1, { textAlign: 'justify', marginHorizontal: '20px', fontSize: 12, marginTop: '20px' }]}>
                                <Text style={styles.textBold}>FIEL DEPOSITÁRIO</Text>: {fiel.nome}, {fiel?.fNacionalidade ? fiel.fNacionalidade + ", " : ""}{fiel.ocupacao ? fiel.ocupacao + ", " : ""} {fiel?.fEstadoCivil ? fiel?.fEstadoCivil + ", " : ""} portador do documento de identidade RG n º {fiel.fRg} e inscrito no CPF/MF sob o n º {fiel.fCpf}, residente e domiciliado {getPreposition(fiel.fRua)} {fiel.fRua}, n º {fiel.fNumero}, {fiel.aComplemento ? fiel.fComplemento + ',' : ''} {fiel.fBairro} {fiel.fCidade}/{fiel.aEstado} CEP {fiel.fCep} e, na forma de seus atos constitutivos, email: {fiel.fEmail}; Na qualidade de Fiel depositário
                            </Text>
                        )}
                        <Text style={[styles.text1, { textAlign: 'justify', marginHorizontal: '20px', fontSize: 12, marginTop: '20px' }]}>
                            Sendo a Emitente, o Escriturador, e o Titular doravante denominados, em conjunto, como “Partes” e, individual e indistintamente, como “Parte”;
                        </Text>
                        {garantiaData && garantiaData?.length > 0 && garantiaData[0]?.gTipo ?
                            <Text style={[styles.text1, { textAlign: 'justify', marginHorizontal: '20px', fontSize: 12, marginTop: '20px' }]}>
                                As Partes, na melhor forma de direito, firmam o presente <Text style={{ textDecoration: 'underline' }}>Termo de Emissão da {dados.emissao} Emissão de Notas Comerciais, com Garantia Real, em Série Única, para Distribuição Privada Com Esforços Restritos</Text>, da {emissor.razaoSocial}
                            </Text>
                            :
                            <Text style={[styles.text1, { textAlign: 'justify', marginHorizontal: '20px', fontSize: 12, marginTop: '20px' }]}>
                                As Partes, na melhor forma de direito, firmam o presente <Text style={{ textDecoration: 'underline' }}>Termo de Emissão da {dados.emissao} Emissão de Notas Comerciais, sem Garantia Real, em Série Única, para Distribuição Privada Com Esforços Restritos</Text>, da {emissor.razaoSocial}
                            </Text>
                        }
                        <Text style={[styles.text1, { textAlign: 'justify', marginHorizontal: '20px', fontSize: 12, marginTop: '20px' }]}>
                            (“Termo de Emissão”, “Notas Comerciais” e “Emissão”, respectivamente), nos termos do artigo
                            45 e seguintes da Lei nº 14.195 de 26 de agosto de 2021, nos seguintes termos e condições:
                        </Text>

                    </View>
                    <View style={styles.section}>
                        {/*TOPICO 1 INICIO */}
                        <Text style={[styles.textBold, { textAlign: 'justify', marginHorizontal: '20px', fontSize: 12, marginTop: '20px' }]}>
                            1.{"   "}AUTORIZAÇÃO
                        </Text>
                        <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12, marginTop: '20px' }]}>
                            1.1{"   "}Os administradores da Emitente estão devidamente autorizados a contrair as obrigações aqui previstas em nome da Emitente e a celebrar o presente Termo de Emissão,
                            nos termos do Parágrafo único, do artigo 46 da Lei nº14.195, de 26 de agosto de 2021 (“Lei 14.195”) e em conformidade com seus Atos Constitutivos e alterações posteriores vigentes nesta data.
                        </Text>
                        <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12, marginTop: '20px' }]}>
                            1.2{"   "}A Emissão está condicionada, nos termos do artigo 125 da Lei 10.406, de 10 de janeiro de 2002, conforme alterada, à verificação do cumprimento dos seguintes atos (“Condições Precedentes”):
                        </Text>
                        <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12, marginTop: '20px' }]}>
                            <Text style={styles.textBold}>a.</Text>{"   "}Perfeita formalização, pela Emitente, e demais partes signatárias, do Termo Constitutivo e do Boletim de Subscrição (conjuntamente, os “Documentos da Operação”), bem como a verificação dos poderes dos representantes dessas partes e eventuais aprovações societárias necessárias à celebração desses documentos;
                        </Text>
                        <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12, marginTop: '20px' }]}>
                            <Text style={styles.textBold}>b.</Text>{"   "}	Recebimento, pela Emitente, do Boletim de Subscrição devidamente formalizado, cujo modelo segue anexo a este Termo Constitutivo no Anexo I.
                        </Text>
                        {/*TOPICO 2 INICIO */}
                        <Text style={[styles.textBold, { fontSize: 12, marginHorizontal: '20px', marginVertical: '10px' }]}>2.{"   "}REQUISITOS</Text>
                        <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12, marginTop: '5px' }]}>
                            2.1{"   "}<Text style={{ textDecoration: 'underline' }}>Registro na CVM</Text>
                        </Text>
                        <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '60px', marginRight: '20px', fontSize: 12, marginTop: '10px' }]}> 2.1.1{" "} Por se tratar de oferta privada de valores mobiliários é dispensado o protocolo, registro e arquivamento perante a CVM.</Text>
                        <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12, marginTop: '10px' }]}>
                            2.2{"   "}<Text style={{ textDecoration: 'underline' }}>Arquivamento e Publicação dos Documentos Societários </Text>
                        </Text>
                        <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '60px', marginRight: '20px', fontSize: 12, marginTop: '10px' }]}> 2.2.1{" "} - Nos termos dos Atos Constitutivos da Emitente a presente Emissão está dispensada da realização de Assembleia geral extraordinária ou reunião do conselho de administração. Não sendo, portanto, necessária a apresentação de Ata de Aprovação da Emissão</Text>
                    </View>
                    {/*TOPICO 3 INICIO */}
                    <Text style={[styles.textBold, { textAlign: 'justify', marginHorizontal: '20px', fontSize: 12, marginTop: '20px' }]}>
                        3.{"   "}CARACTERÍSTICAS DA EMISSÃO
                    </Text>
                    <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12, marginTop: '20px' }]}>
                        3.1{"   "}<Text style={{ textDecoration: 'underline' }}>Número da Emissão</Text>
                    </Text>
                    <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '60px', marginRight: '20px', fontSize: 12, marginTop: '20px' }]}>
                        3.1.1{"   "}A presente Emissão representa a {dados.emissao} emissão de Notas Comerciais da Emitente.
                    </Text>
                    <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12, marginTop: '20px' }]}>
                        3.2{"   "
                        }<Text style={{ textDecoration: 'underline' }}>Valor Total da Emissão
                        </Text>
                    </Text>
                    <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '60px', marginRight: '20px', fontSize: 12, marginTop: '20px' }]}>
                        3.2.1{"   "}O valor total da emissão será de {allInfoArray[13]} na data de emissão (conforme definido abaixo) (“Valor Total da Emissão”).
                    </Text>
                    <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '60px', marginRight: '20px', fontSize: 12, marginTop: '20px' }]}>
                        3.2.2{"   "} O valor total da emissão representa um limite de crédito aberto para a
                        Emitente. A emissão de cada série está condicionada ao cumprimento de todas as
                        obrigações assumidas pelo Emitente em relação à série anterior.
                    </Text>
                    <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '60px', marginRight: '20px', fontSize: 12, marginTop: '20px' }]}>
                        3.2.3{"  "}O valor total da emissão pode não ser alcançado ou liberado para a Emitente,
                        bem como pode ser suspenso ou até extinto, nas seguintes hipóteses: a)
                        indisponibilidade momentânea de recursos por parte do Titular; b) mora ou
                        inadimplemento de qualquer parcela ou série anterior por parte do Emitente; c)
                        descumprimento de qualquer obrigação assumida pelo Emitente neste Termo ou em
                        qualquer outro; d) existência de pendência financeira, protesto, ou ação judicial, que
                        possa colocar em risco o adimplemento de qualquer série ou a execução da garantia
                        prestada à operação, a critério do Titular; e) paralisação ou redução das atividades
                        do Emitente, que possa colocar em risco o adimplemento de qualquer série ou a
                        execução da garantia prestada à operação, a critério do Titular; f) proposta de
                        recuperação extrajudicial ou distribuição de pedido de recuperação judicial ou de
                        falência relacionado ao Emitente
                    </Text>
                    <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12, marginTop: '20px' }]}>
                        3.3{"   "}<Text style={{ textDecoration: 'underline' }}>Número de Séries
                        </Text>
                    </Text>
                    <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '60px', marginRight: '20px', fontSize: 12, marginTop: '20px', marginBottom: '20px' }]}>
                        3.3.1{"   "}A emissão será realizada em série única.
                    </Text>
                    <View style={styles.section}>
                        <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12 }]}>
                            3.4{"   "}<Text style={{ textDecoration: 'underline' }}>Quantidade de Notas Comerciais</Text>
                        </Text>
                        <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '60px', marginRight: '20px', fontSize: 12, marginTop: '20px' }]}>
                            3.4.1{"   "}Será emitida 1 (uma) Nota Comercial em série única.
                        </Text>
                        <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12, marginTop: '20px' }]}>
                            3.5{"   "}<Text style={{ textDecoration: 'underline' }}>Valor Nominal Unitário</Text>
                        </Text>
                        <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '60px', marginRight: '20px', fontSize: 12, marginTop: '20px' }]}>
                            3.5.1{"   "}O valor nominal unitário da Nota Comercial será de {allInfoArray[13]} na data de emissão (“valor nominal unitário”).
                        </Text>
                        <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '60px', marginRight: '20px', fontSize: 12, marginTop: '20px' }]}>
                            3.6.1{"   "}Os recursos líquidos captados por meio da emissão serão destinados a título de capital de giro da Emitente.
                        </Text>
                        <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', fontSize: 12, marginTop: '20px', marginBottom: '20px' }]}>3.7{"   "}
                            <Text style={{ textDecoration: 'underline' }}>Escriturador
                            </Text>
                        </Text>
                        <Text style={[styles.text1, { fontSize: 12, marginLeft: '60px', marginRight: '20px', marginBottom: '20px' }]}>3.7.1.{"   "}
                            <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '30px', marginRight: '20px', fontSize: 12 }]}>O Escriturador da presente emissão é a OSLO CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A, inscrita CNPJ/ME nº. 13.673.855/0001-25, Rua Doutor Eduardo de Souza Aranha, nº 153, 4º andar, CEP 04543-120, na cidade e comarca de São Paulo, estado de São Paulo neste ato representada na forma de seu contrato social por seus representantes legais ao final subscritos, (“Escriturador”);. O Escriturador será responsável por realizar a escrituração das Notas Comerciais, entre outras responsabilidades eventualmente definidas nas normas aplicáveis editadas pela Comissão de Valores Mobiliários, Conselho Monetário Nacional e Banco Central do Brasil.
                            </Text>
                        </Text>
                        <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginBottom: '10px', marginLeft: '60px', marginRight: '20px', }]}>3.7.2.
                            <Text style={[styles.text1, { textAlign: 'justify', marginLeft: '40px', marginRight: '20px', marginBottom: '20px', fontSize: 12 }]}>{"   "}As definições acima incluem quaisquer outras instituições que venham a suceder o Agente de liquidação e/ou o Escriturador na prestação dos serviços relativos às Notas Comerciais.</Text>
                        </Text>
                        {/*TOPICO 4 INICIO */}
                        <Text style={[styles.textBold, { fontSize: 12, marginVertical: '0px', marginHorizontal: '20px', marginTop: '20px', textAlign: 'justify' }]}>4.{"   "}CARACTERÍSTICAS GERAIS DAS NOTAS COMERCIAIS
                        </Text>
                        {/*4.1*/}
                        <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '40px', marginRight: '20px', marginTop: '20px' }]}>4.1{"   "}<Text style={{ textDecoration: 'underline' }}>Local de Emissão: </Text></Text>
                        <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '10px' }]}>4.1.1 Para todos os fins e efeitos legais, o local de emissão das Notas Comerciais será {emissor.cidade}, {emissor.estado}.</Text>
                        {/*4.2*/}
                        <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '40px', marginRight: '20px', marginTop: '20px' }]}>4.2{"   "}<Text style={{ textDecoration: 'underline' }}>Data de Emissão: </Text></Text>
                        <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '10px' }]}>4.2.1 Para todos os fins e efeitos legais, a data de emissão das Notas Comerciais será o dia {dataEmissao}.</Text>
                        {/*4.3*/}
                        <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '40px', marginRight: '20px', marginTop: '20px' }]}>4.3{"   "}<Text style={{ textDecoration: 'underline' }}>Data de Início da Rentabilidade: </Text></Text>
                        <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '10px' }]}> 4.3.1 Para todos os fins e efeitos legais, a data de início da rentabilidade [e aplicação da atualização monetária [prefixadas ou indexadas na taxa de Depósito Interfinanceiro (DI), na taxa Selic ou na taxa Referencial (TR)] (conforme abaixo definido)] será a data da primeira subscrição e integralização de Notas Comerciais (“Data de Início da Rentabilidade” e “Data da Primeira Integralização”, respectivamente).</Text>
                        {/*4.4*/}
                        <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '40px', marginRight: '20px', marginTop: '20px' }]}>4.4{"   "}<Text style={{ textDecoration: 'underline' }}>Forma, Tipo e Comprovação de Titularidade: </Text></Text>
                        <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '10px' }]}> 4.4.1 As Notas Comerciais serão emitidas sob a forma escritural, sem emissão de cautelas ou certificados, sendo que, para todos os fins de direito, a titularidade das Notas Comerciais será comprovada pelo extrato emitido pelo Escriturador</Text>
                        {/*4.5*/}
                        <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '40px', marginRight: '20px', marginTop: '20px' }]}>4.5{"   "}<Text style={{ textDecoration: 'underline' }}>Prazo e Data de Vencimento: </Text></Text>
                        <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '10px' }]}>4.5.1 Observado o disposto neste Termo de Emissão, as Notas Comerciais da Série única terão prazo de {totalDias} dias, vencendo-se, portanto, em {dataDoVencimento}.</Text>
                        <View style={styles.container}>
                            {/*4.6*/}
                            <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '40px', marginRight: '20px', marginTop: '20px' }]}>4.6{"   "}<Text style={{ textDecoration: 'underline' }}>Juros remuneratórios e Plano de Pagamento:</Text>
                            </Text>
                            <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px' }]}>4.6.1{"   "}
                                Juros Remuneratórios: os juros remuneratórios Notas Comerciais da série única serão de {allInfoArray[10]} a.m. ({allInfoArray[10]} por cento ao mês) base exponencial 360 (trezentos e sessenta) dias corridos, ou {allInfoArray[20]} a.a. ({numero.porExtenso(allInfoArray[20], numero.estilo.porcentagem)} ao ano) sendo o {parcelas > 1 ? 'pagamento final' : 'pagamento único'} em {dataDoVencimento}.
                            </Text>
                            <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px' }]}>4.6.2{"   "}
                                Plano de Pagamento: no Plano de Pagamento será detalhado todo o fluxo de parcelas para pagamento das Notas Comerciais, contendo datas de vencimentos das parcelas, juros, saldo devedor, apresentado em Anexo II ao presente instrumento.
                            </Text>
                        </View>
                    </View>
                    {/*4.7*/}
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '40px', marginRight: '20px', marginTop: '20px' }]}>4.7{"   "}<Text style={{ textDecoration: 'underline' }}>Local do Pagamento:</Text></Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '10px', marginBottom: '30px' }]}> São Paulo/SP, em conta do titular da Nota Comercial ora identificada: número da conta {emissor.conta}, na Agência {emissor.agency}, na instituição {emissor.banco} e número do banco {emissor.bankNumber}.</Text> {/*ajuste dinamico 1*/}
                    {/*4.8*/}
                    <View style={styles.section}>
                        <Text style={[styles.text1, { fontSize: 12, marginVertical: '0px', marginLeft: '40px', marginRight: '20px', marginTop: '10px' }]}>4.8{"   "}
                            <Text style={{ textDecoration: 'underline' }}>Preço de Subscrição e Forma de Integralização:
                            </Text>
                        </Text>
                        <Text style={[styles.text1, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px', textAlign: 'justify' }]}>4.8.1. <Text>
                            As Notas Comerciais serão subscritas, nos termos do Boletim de Subscrição, ora em anexo (Anexo I) e integralizadas à vista, em moeda corrente nacional, no ato da subscrição, pelo seu valor nominal unitário acrescido da remuneração, calculada pro rata temporis a partir da data de início da rentabilidade, de acordo com as normas de liquidação aplicáveis à B3. Caso seja possível a integralização em mais de uma data, a Nota Comercial que venha ser integralizada em data diversa e posterior à primeira Data de Integralização deverá ser integralizada considerando o seu valor nominal unitário acrescido da remuneração, calculada pro rata temporis desde a data de início da rentabilidade (inclusive) até a data de sua efetiva integralização (exclusive).
                        </Text>

                        </Text>
                        <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px' }]}>4.8.2. <Text>
                            As Notas Comerciais poderão ser subscritas com ágio ou deságio,
                            a ser definido, de comum acordo entre a Emitente e as instituições intermediárias,
                            no ato de subscrição das Notas Comerciais, observado que referido ágio ou deságio
                            deverá ser aplicado de forma igualitária à totalidade dos titulares das Notas
                            Comerciais, se houver.
                        </Text>
                        </Text>
                        {/*4.9*/}
                        <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '40px', marginRight: '20px', marginTop: '20px' }]}>
                            4.9.{"   "}
                            <Text style={{ textDecoration: 'underline' }}>
                                Atualização Monetária das Notas Comerciais
                            </Text>
                        </Text>
                        <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px' }]}>
                            4.9.1{"   "}
                            <Text style={{ textDecoration: 'underline' }}>
                                Indexador:
                            </Text>
                        </Text>
                        <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px' }]}>
                            <Text style={styles.textBold}>Para fins de correção monetária, o indexador incidirá sobre o saldo devedor, nos termos da Plano de Pagamento (Planilha de Cálculo) constante do Anexo II.{'\n'}
                                {dados.indexador !== 'Pré-Fixado' ? '(x)Pós-fixado' : ''}{dados.prcntgm ? ` ${dados.prcntgm}% do ` : ''}</Text>{dados.indexador ? dados.indexador : 'Pré-Fixado'} da taxa média dos Depósitos Interfinanceiros DI de um dia, “over extra grupo”, expressa na forma percentual ao ano calculada e divulgada pela B3 S/A BRASIL, BOLSA, BALCÃO (“Taxa DI” e “B3”, respectivamente^&.*xyz$
                            {'\n'}
                        </Text>
                        <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px' }]}>
                            4.9.2.{"   "}
                            <Text>
                                Em caso de não haver nenhuma marcação de indexador no item 4.9.1 o valor nominal unitário
                                das Notas Comerciais da série única não será atualizado monetariamente.
                            </Text>
                        </Text>
                        <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px', marginBottom: '20px' }]}>
                            4.9.3.{"   "}
                            <Text style={{ textDecoration: 'underline' }}>
                                Taxa Substitutiva sem deliberação em assembleia
                            </Text>
                        </Text>
                        <Text style={[styles.textJustify,
                        {
                            fontSize: 12, marginVertical: '0px',
                            marginLeft: '65px', marginRight: '20px',
                            marginBottom: '20px'
                        }]}>
                            4.9.3.1{"   "}
                            <Text wrap>
                                Na ausência de apuração e/ou divulgação do DI
                                por prazo superior a 30 (trinta) dias contados da
                                data esperada para sua apuração e/ou divulgação
                                ( “Período de Ausência do DI” )  ou, ainda, na hipótese
                                de  extinção  ou  inaplicabilidade por disposição legal
                                ou determinação judicial, o DI deverá ser substituído
                                pelo seu substituto legal ou, no caso de inexistir
                                substituto legal para o DI, deverá ser considerado
                                o índice que tenha substancialmente os mesmos atributos
                                do DI, novo parâmetro que deverá refletir parâmetros
                                utilizados em operações similares existentes à época a
                                ser evidenciado pela Emitente (“Taxa Substitutiva das
                                Notas Comerciais da Série única).
                            </Text>
                        </Text>
                        <Text style={[styles.textJustify,
                        {
                            fontSize: 12, marginVertical: '0px',
                            marginLeft: '65px', marginRight: '20px',
                        }]}>
                            4.9.3.2{"   "}
                            <Text wrap>
                                Caso o DI venha a ser divulgado antes da definição da Taxa Substitutiva das Notas Comerciais da série única, o DI a partir de sua divulgação voltará a ser utilizado para o cálculo do valor nominal unitário atualizado das Notas Comerciais da série única desde o dia de sua indisponibilidade
                            </Text>
                        </Text>
                        {/*TOPICO 5 INICIO */}
                        <View style={styles.section}>
                            <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '20px', marginRight: '20px', marginTop: '20px' }]}>
                                5.{"   "}
                                <Text style={styles.textBold}>
                                    VENCIMENTO ANTECIPADO
                                </Text>
                            </Text>
                            <Text style={[styles.textJustify, {
                                fontSize: 12,
                                marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                                marginTop: '20px'
                            }]}>
                                5.1.{"   "}
                                <Text>
                                    Observado o disposto nesta cláusula, serão consideradas antecipadamente vencidas as obrigações decorrentes das Notas Comerciais e exigível o imediato pagamento pela Emitente do Valor Nominal Unitário, acrescido da remuneração, calculada pro rata temporis, e dos encargos
                                    moratórios e multas, se houver, incidentes até a data do seu efetivo pagamento, na ocorrência de quaisquer das situações previstas nesta cláusula, respeitados os respectivos prazos de cura (cada um desses eventos, um “Evento de Vencimento Antecipado”):
                                </Text>
                            </Text>
                            <Text style={[styles.textJustify, {
                                fontSize: 12,
                                marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                marginTop: '20px'
                            }]}>
                                5.1.1.{"   "}
                                <Text>
                                    Observados os eventuais prazos de cura aplicáveis, a ocorrência de quaisquer dos eventos indicados nesta cláusula 5.1.1 acarretará o vencimento antecipado automático das Notas Comerciais, independentemente de qualquer aviso extrajudicial, interpelação judicial, notificação prévia à Emitente ou consulta ao Titular das Notas Comerciais, exceto se previsto de modo diverso, abaixo (cada um desses eventos, um “Evento de Vencimento Antecipado Automático”):
                                </Text>
                            </Text>
                            <Text style={[styles.textJustify, {
                                fontSize: 12,
                                marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                marginTop: '20px'
                            }]}>
                                {"a)   "}
                                <Text>
                                    Se ocorrer inadimplemento de qualquer obrigação assumida pela Emitente em consonância com as cláusulas e condições aqui estabelecidas, principalmente no que tange ao pagamento das parcelas devidas em decorrências desta Emissão;</Text>
                            </Text>
                            <Text style={[styles.textJustify, {
                                fontSize: 12,
                                marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                marginTop: '20px'
                            }]}>
                                {"b)   "}
                                <Text>
                                    Se ocorrer inadimplemento de qualquer obrigação assumida pela Emitente e/ou quaisquer sociedades direta ou indiretamente ligadas, coligadas, controladoras ou controladas pela Emitente, inclusive no exterior, de suas obrigações decorrentes de outros contratos, empréstimos ou descontos celebrados com o Titular das Notas Comerciais e/ou quaisquer sociedades, direta ou indiretamente, ligadas, coligadas, controladoras ou controladas pelo Titular da Nota Comercial ou seu cessionário, e/ou com terceiros, e/ou rescisão ou declaração de vencimento antecipado dos respectivos documentos, por culpa da Emitente e/ou de quaisquer de suas afiliadas;
                                </Text>
                            </Text>
                            <Text style={[styles.textJustify, {
                                fontSize: 12,
                                marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                marginTop: '20px'
                            }]}>
                                {"c)   "}
                                <Text>
                                    Se a Emitente for inscrita no Cadastro de Emitentes de Cheques sem Fundo – CCF, ou, ainda, constem informações negativas a seu respeito no Sistema de Informações de Crédito do Banco Central, que, a critério do Titular das Notas Comerciais, possam afetar a sua capacidade de cumprir as obrigações assumidas na Notas Comerciais;
                                </Text>
                            </Text>
                            <Text style={[styles.textJustify, {
                                fontSize: 12,
                                marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                marginTop: '20px'
                            }]}>
                                {"d)   "}
                                <Text>
                                    Se a Emitente se tornar insolvente, requerer ou tiver falência, insolvência civil, recuperação judicial ou extrajudicial decretada, sofrer intervenções, regime de administração especial temporária, ou liquidação judicial ou extrajudicial;
                                </Text>
                            </Text>
                            <Text style={[styles.textJustify, {
                                fontSize: 12,
                                marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                marginTop: '20px'
                            }]}>
                                {"e)   "}
                                <Text>
                                    Se for comprovada a falsidade de qualquer declaração, informação ou documento que houver sido respectivamente firmada, prestada ou entregue pela Emitente que se fossem do conhecimento do Titular de Notas Comerciais poderiam alterar o julgamento a respeito da subscrição e integralização das Notas Comerciais;</Text>
                            </Text>
                            <Text style={[styles.textJustify, {
                                fontSize: 12,
                                marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                marginTop: '20px'
                            }]}>
                                {"f)   "}
                                <Text>
                                    Se a Emitente sofrer qualquer medida judicial ou extrajudicial que por qualquer forma, possa afetar negativamente os créditos do empréstimo e/ou as garantias conferidas ao Titular da Nota Comercial;</Text>
                            </Text>
                            <Text style={[styles.textJustify, {
                                fontSize: 12,
                                marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                marginTop: '20px'
                            }]}>
                                {"g)   "}
                                <Text>
                                    Se a garantia da Nota Comercial, ora constituída e/ou que venha a ser eventualmente convencionada, por qualquer fato atinente ao seu objeto ou prestador se tornar inábil, imprópria, ou insuficiente para assegurar o pagamento da dívida, e desde que não seja substituída, ou complementada, quando solicitada por escrito pelo Titular ou a quem este vier a indicar;</Text>
                            </Text>
                            <Text style={[styles.textJustify, {
                                fontSize: 12,
                                marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                marginTop: '20px'
                            }]}>
                                {"h)   "}
                                <Text>
                                    Se a Emitente realizar qualquer operação de mútuo (como mutuante) para qualquer empresa de seu grupo econômico ou terceiros, exceto se com o prévio e expresso consentimento do Titular das Notas Comerciais;</Text>
                            </Text>
                            <Text style={[styles.textJustify, {
                                fontSize: 12,
                                marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                marginTop: '20px'
                            }]}>
                                {"i)   "}
                                <Text>
                                    Se, sem o expresso consentimento do Titular das Notas Comerciais ocorrer a transferência a terceiros dos direitos e obrigações da Emitente, previstos neste Termo Constitutivo;</Text>
                            </Text>
                            <Text style={[styles.textJustify, {
                                fontSize: 12,
                                marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                marginTop: '20px'
                            }]}>
                                {"j)   "}
                                <Text>
                                    Se, sem o expresso consentimento do Titular das Notas Comerciais ocorrer alienação, cessão, doação ou transferência, por qualquer meio, de bens, ativos ou direitos de propriedade da Emitente, que, no entendimento do Titular, possam levar ao descumprimento das obrigações previstas neste Termo de Emissão;</Text>
                            </Text>
                            <Text style={[styles.textJustify, {
                                fontSize: 12,
                                marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                marginTop: '20px'
                            }]}>
                                {"k)   "}
                                <Text>
                                    Se, sem o expresso consentimento do Titular das Notas Comerciais, a Emitente tiver total ou parcialmente, o seu controle acionário, direto ou indireto, cedido, transferido ou por qualquer outra forma alienado ou modificado;</Text>
                            </Text>
                            <Text style={[styles.textJustify, {
                                fontSize: 12,
                                marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                marginTop: '20px'
                            }]}>
                                {"l)   "}
                                <Text>
                                    Se ocorrer mudança ou alteração do objeto social da Emitente, de forma a alterar as atividades principais ou a agregar às suas atividades novos negócios que possam representar desvios em relação às atividades atualmente desenvolvida;</Text>
                            </Text>
                            <Text style={[styles.textJustify, {
                                fontSize: 12,
                                marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                marginTop: '20px'
                            }]}>
                                {"m)   "}
                                <Text>
                                    Se a Emitente sofrer cisão, fusão, incorporação ou qualquer outro tipo de reorganização societária, que possam levar ao descumprimento de obrigações previstas no Termo de Emissão;</Text>
                            </Text>
                            <Text style={[styles.textJustify, {
                                fontSize: 12,
                                marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                marginTop: '20px'
                            }]}>
                                {"n)   "}
                                <Text>
                                    Se a Emitente sofrer qualquer (quaisquer) medida(s) judicial(ais) ou extrajudicial(ais) que por qualquer forma, possa(m) afetar negativamente os créditos e/ou as garantias conferidas ao Titular da Notas Comerciais.</Text>
                            </Text>

                        </View>
                        <Text style={[styles.textJustify, {
                            fontSize: 12,
                            marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                            marginTop: '20px'
                        }]}>
                            5.2.{"   "}
                            <Text>
                                No caso de falta de pagamento de qualquer parcela(s) na(s) data(s) de seu(s) respectivo(s) vencimento(s), o Titular ou a quem este vier a indicar, poderá, por mera liberdade e sem que tal situação caracterize novação ou alteração das condições estabelecidas neste instrumento – optar pela cobrança somente da(s) parcela(s) devida(s) em aberto, comprometendo-se a Emitente, em contrapartida, a liquidá-la(s) imediatamente quando instado(s) para tal, sob pena de ultimar-se o vencimento antecipado de toda a dívida;
                            </Text>
                        </Text>
                        <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px' }]}>
                            5.2.1{"   "}
                            <Text>
                                Em caso do vencimento antecipado das obrigações decorrentes das Notas Comerciais, será apresentado à Emitente notificação contendo o saldo devedor final, quando restará a Emitente obrigada a efetuar o pagamento da totalidade das Notas Comerciais pelo Valor Nominal Unitário, acrescido da Remuneração, calculada pro rata temporis, desde a Data de Início da Rentabilidade, até a data do efetivo resgate, sem prejuízo do pagamento dos Encargos Moratórios, quando for o caso, e de quaisquer outros valores eventualmente devidos pela Emitente nos termos deste Termo de Emissão, incluindo eventuais despesas vencidas e não pagas, no dia útil imediatamente subsequente ao recebimento de referida notificação, sob pena de ser considerado em mora, independentemente de qualquer aviso ou notificação judicial ou extrajudicial, nos termos previstos nesta Cláusula, sendo certo que tal pagamento é devido pela Emitente desde a data da declaração do vencimento antecipado, podendo o Titular das Notas Comerciais adotar todas as medidas necessárias para a satisfação do seu crédito.
                            </Text>
                        </Text>
                        <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px' }]}>
                            5.2.2{"   "}
                            <Text>
                                Caso o pagamento da totalidade das Notas Comerciais previsto na Cláusula.
                            </Text>
                        </Text>
                        <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px' }]}>
                            5.2.3{"   "}
                            <Text>
                                Acima seja realizado por meio da B3, a Emitente deverá comunicar a B3, por meio de correspondência sobre o tal pagamento, com, no mínimo, 3 (três) Dias Úteis de antecedência da data estipulada para a sua realização.
                            </Text>
                        </Text>
                    </View>
                    {/*TOPICO 6 INICIO */}
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '20px'
                    }]}>
                        6.{"   "}
                        <Text style={styles.textBold}>
                            ATRASO NO PAGAMENTO E ENCARGOS MORATÓRIOS
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        6.1{"   "}
                        <Text>
                            Na hipótese de inadimplemento ou mora dos valores devidos no âmbito deste Termo Constitutivo, a Emitente estará obrigada a pagar ao titular, cumulativamente, além da quantia correspondente à dívida em aberto, os seguintes encargos:
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px' }]}>
                        6.1.1{"   "}
                        <Text>
                            Juros remuneratórios nos mesmos percentuais das taxas constantes neste Termo Constitutivo, calculados a partir do vencimento das parcelas em aberto até a data do efetivo pagamento;
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px' }]}>
                        6.1.2{"   "}
                        <Text>
                            Juros de mora à razão de 1% a.m. (um por cento ao mês), calculados a partir do vencimento das parcelas em aberto até a data do efetivo pagamento pela Emitente;
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px' }]}>
                        6.1.3{"   "}
                        <Text>
                            Multa contratual, de natureza não compensatória, de 2% (dois por cento) incidente sobre o montante total atualizado (incluindo juros remuneratórios e juros de mora) do valor devido e não pago;
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '20px' }]}>
                        6.1.4{"   "}
                        <Text>
                            Na hipótese de o Titular vir a ser compelido a recorrer a meios administrativos ou judiciais para receber os valores devidos no âmbito deste Termo Constitutivo, as despesas de cobrança administrativa, limitadas a 10% (dez por cento) sobre o valor do saldo devedor deste Termo Constitutivo e, havendo procedimento judicial, custas processuais e honorários advocatícios, estes fixados judicialmente.
                        </Text>
                    </Text>
                    {/*TOPICO 7 INICIO */}
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '20px'
                    }]}>
                        7.{"   "}
                        <Text style={styles.textBold}>
                            DECLARAÇÕES
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        7.1{"   "}
                        <Text>
                            As Partes signatárias declaram e garantem que:
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        7.1.1{"   "}
                        <Text>
                            Possuem plena capacidade e legitimidade para celebrar o presente Termo Constitutivo, realizar todas as operações e cumprir todas as obrigações assumidas tendo tomado todas as medidas de natureza societária, conforme aplicável, e outras eventualmente necessárias para autorizar a sua celebração, implementação e cumprimento de todas as obrigações constituídas;
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        7.1.2{"   "}
                        <Text>
                            A celebração deste Termo Constitutivo e o cumprimento das obrigações nele dispostos: (a) não violam qualquer disposição contida nos seus documentos societários, conforme aplicável; (b) não violam qualquer lei, regulamento, decisão judicial, administrativa ou arbitral, aos quais a respectiva parte esteja vinculada; (c) não exigem qualquer consentimento ação ou autorização, prévia ou posterior, de terceiros;
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        7.1.3{"   "}
                        <Text>
                            Este Termo Constitutivo é validamente celebrado e constitui obrigação legal, válida, vinculante e exequível, de acordo com os seus termos;
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        7.1.4{"   "}
                        <Text>
                            Estão aptas a cumprir as obrigações ora previstas neste Termo Constitutivo e agirão em relação às mesmas de boa-fé e com lealdade;

                        </Text>
                    </Text>
                    {/*colocar dinamico*/}
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                        marginTop: '20px', marginBottom: '30px'
                    }]}>
                        7.1.5{"   "}
                        <Text>
                            Não se encontram em estado de necessidade ou sob coação para celebrar este Termo Constitutivo e/ou quaisquer contratos e compromissos a ele relacionados e
                            acessórios;
                        </Text>
                    </Text>
                    {/*TOPICO 8 INICIO */}
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '20px'
                    }]}>
                        8.{"   "}
                        <Text style={styles.textBold}>
                            DISPOSIÇÕES GERAIS
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        8.1{"  "}
                        <Text>
                            Este instrumento e as Notas Comerciais constituem títulos executivos extrajudiciais, nos termos dos incisos I e III do artigo 784 do
                            Código de Processo Civil, do inciso XI da Lei no 13.105 de 16 de março de 2015 e do artigo 47 da Lei no 14.195 de 26 de agosto de 2021,
                            as partes desde já que, independentemente de quaisquer outras medidas cabíveis, as obrigações assumidas nos termos deste instrumento e com relação às Notas Comerciais estão sujeitas à execução específica, submetendo-se às disposições dos artigos 815 e seguintes do Código de Processo Civil, sem prejuízo do direito de declarar o vencimento antecipado das Notas Comerciais, nos termos deste instrumento.
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        8.2{"   "}
                        <Text>
                            A Nota Comercial e o presente instrumento são celebrados em caráter irrevogável e irretratável, obrigando as partes e seus sucessores a qualquer título.
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        8.3{"   "}
                        <Text>
                            Não se presume a renúncia a qualquer dos direitos decorrentes da Nota Comercial. Desta forma, nenhum atraso, omissão ou liberalidade no exercício de qualquer direito, faculdade ou prerrogativa que caiba ao Titular, em razão de qualquer inadimplemento da Emitente, prejudicará o exercício de tais direitos, faculdades ou remédios, ou será interpretado como constituindo uma renúncia aos mesmos ou concordância com tal inadimplemento, nem constituirá novação ou modificação de quaisquer outras obrigações assumidas pela Emitente neste Termo de Emissão, ou precedente no tocante a qualquer outro inadimplemento ou atraso.
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        8.4{"   "}
                        <Text>
                            O presente Termo de Emissão é firmado em caráter irrevogável e irretratável, obrigando as Partes por si e seus sucessores.
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        8.5{"   "}
                        <Text>
                            Caso qualquer das disposições deste Termo de Emissão venha a ser julgada ilegal, inválida, ineficaz, nula ou inexequível, prevalecerão todas as demais disposições não afetadas por tal julgamento, comprometendo-se as Partes, em boa-fé, a substituir a disposição afetada por outra que, na medida do possível, produza o mesmo efeito.
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        8.6{"   "}
                        <Text>
                            O presente Termo de Emissão e as Notas Comerciais constituem título executivo
                            extrajudicial, nos termos do artigo 47 da Lei nº 14.195 de 26 de agosto de 2021 e do artigo 784, inciso XI da Lei nº 13.105 de 16 de março de 2015 (“Código de Processo Civil”),
                            reconhecendo as Partes desde já que, independentemente de quaisquer outras medidas cabíveis, as obrigações assumidas nos termos deste Termo de Emissão e com
                            relação às Notas Comerciais estão sujeitas a execução específica, submetendo-se às
                            disposições dos artigos 815 e seguintes do Código de Processo Civil, sem prejuízo do
                            direito de declarar o vencimento antecipado das Notas Comerciais, nos termos deste
                            Termo de Emissão.</Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        8.7{"   "}
                        <Text>
                            Exceto quando previsto expressamente de modo diverso na presente Escritura de Emissão, entende-se por “Dia(s) Útil(eis)” qualquer dia em que não seja feriado declarado nacional, sábado ou domingo.</Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        8.8{"   "}
                        <Text>
                            Todas e quaisquer despesas incorridas com a Emissão e/ou com a execução de valores devidos nos termos deste Termo de Emissão, publicações, inscrições, registros, averbações, contratação dos prestadores de serviços envolvidos na Emissão, bem como quaisquer outros custos relacionados às Notas Comerciais, serão de responsabilidade exclusiva da Emitente, nos termos deste Termo de Emissão.</Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        8.9{"   "}
                        <Text>
                            Assinatura Digital. As Partes reconhecem que as declarações de vontade das Partes contratantes mediante assinatura digital presumem-se verdadeiras em relação aos signatários quando é utilizado o processo de certificação disponibilizado pela Infraestrutura de Chaves Públicas Brasileira – ICP-Brasil, conforme admitido pelo artigo 10 e seu parágrafo primeiro da Medida Provisória nº 2.200, de 24 de agosto de 2001, em vigor no Brasil, reconhecendo essa forma de contratação em meio eletrônico, digital e informático como válida e plenamente eficaz, constituindo título executivo extrajudicial para todos os fins de direito. Na forma acima prevista, o presente Termo de Emissão, seus eventuais aditamentos, assim como os demais documentos relacionados à Emissão e/ou às Notas Comerciais, poderão ser assinados digitalmente por meio eletrônico conforme disposto nesta Cláusula.</Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                        marginTop: '20px'
                    }]}>
                        8.9.1{"   "}
                        <Text>
                            Este Termo de Emissão e seus eventuais aditamentos produzirão efeitos para todas as Partes a partir das datas neles indicadas, ainda que uma ou mais Partes realizem a assinatura eletrônica em data posterior.
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px',
                    }]}>
                        8.10{"   "}
                        <Text>
                            Lei Aplicável. Este Termo de Emissão é regido pelas leis da República Federativa do Brasil.
                        </Text>
                    </Text>

                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                        marginTop: '20px',
                    }]}>
                        8.11{"   "}
                        <Text>
                            Foro. As Partes elegem o foro da Comarca de São Paulo, Estado de São Paulo, com renúncia expressa de qualquer outro, por mais privilegiado que seja, para dirimir as questões porventura oriundas deste Termo de Emissão e de todas as séries subsequentes.
                        </Text>
                    </Text>
                    {/*TOPICO 9 INICIO */}
                    <Text style={[styles.textJustify, {
                        fontSize: 12,
                        marginVertical: '0px', marginHorizontal: '20px',
                        marginTop: '20px'
                    }]}>
                        9.{"   "}
                        <Text style={styles.textBold}>
                            GARANTIA
                        </Text>
                    </Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '40px', marginRight: '20px', marginTop: '20px' }]}>9.1{"   "}<Text style={{ textDecoration: 'underline' }}>Garantia Fidejussória</Text></Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '10px', marginBottom: '10px' }]}> 9.1.1 Aval. devidamente descritos e caracterizados na página 02 Comparece no presente Termo Constitutivo, em caráter irrevogável e irretratável, na condição de avalista, principais pagadores e responsáveis solidários com relação às Obrigações Garantidas.</Text> {/*ajuste dinamico 1*/}
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '10px', marginBottom: '10px' }]}> 9.1.2 O Avalista, na condição de devedor solidário e principal pagador, juntamente com a Emissora, perante o Titular, para o adimplemento das Obrigações Garantidas, assinam o presente Termo Constitutivo e declaram estarem cientes da presente garantia, aceitando todos os termos, condições e responsabilidades que daí advenham, sem a existência de qualquer benefício de ordem entre a Emissora e o Avalista.</Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '10px', marginBottom: '10px' }]}> 9.1.3 O presente Aval entrará em vigor na Data de Emissão e permanecerá válido enquanto existirem Obrigações Garantidas, extinguindo-se imediata e automaticamente mediante seu integral cumprimento.</Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '10px', marginBottom: '10px' }]}> 9.1.4 O Aval aqui previsto considera-se prestado a título oneroso, de forma que o Avalista possui interesse econômico no resultado da operação, beneficiando-se indiretamente da mesma.</Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '10px', marginBottom: '10px' }]}> 9.1.5 Uma vez declarado o vencimento antecipado das Notas Comerciais, cabe ao Titular requerer a execução judicial ou extrajudicial, do Aval. O Aval poderá ser excutido e exigido pela mesma quantas vezes forem necessárias para o integral pagamento das obrigações garantidas pelo Aval contra o Avalista.</Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '10px', marginBottom: '10px' }]}> 9.1.6 O Avalista deverá pagar as Obrigações Garantidas em até 2 (dois) Dias Úteis contados da data do respectivo inadimplemento da Emissora.</Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '10px', marginBottom: '10px' }]}> 9.1.7 Fica desde já certo e ajustado que a inobservância, pelo Titular, dos prazos para execução do Aval não ensejará, sob hipótese nenhuma, perda de qualquer direito ou faculdade aqui previsto, podendo o Aval ser excutido e exigido pelo Titular, judicial ou extrajudicialmente, quantas vezes forem necessárias até a integral liquidação das Obrigações Garantidas.</Text>
                    <Text style={[styles.textJustify, { fontSize: 12, marginVertical: '0px', marginLeft: '60px', marginRight: '20px', marginTop: '10px', marginBottom: '5px' }]}> 9.1.8 Os pagamentos que vierem a ser realizados pelo Avalista com relação às Notas Comerciais serão realizados de modo que o Titular receba do Avalista os valores que lhes seriam entregues caso esses pagamentos tivessem sido realizados pela Emissora, não cabendo ao Avalista realizar qualquer dedução que não seria realizada pela Emissora, caso a Emissora tivesse realizado o respectivo pagamento.</Text>
                    {/*TOPICO 9.1.2   QUANDO HOUVER GARANTIA REAL */}
                    {garantiaData && garantiaData.length > 0 && garantiaData[0]?.gTipo && (
                        <>
                            <Text style={[styles.textJustify, {
                                fontSize: 12,
                                marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                                marginTop: '20px'
                            }]}>
                                9.2{"  "}
                                <Text>
                                    Garantia Real
                                </Text>
                            </Text>
                            <Text style={[styles.textJustify, {
                                fontSize: 12,
                                marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                marginTop: '20px', marginBottom: '20px'
                            }]}>
                                9.2.1{"   "}
                                <Text>
                                    Em garantia do fiel, pontual, cabal e pronto cumprimento das obrigações de pagamento, principais ou acessórias, presentes ou futuras, decorrentes das Notas Comerciais, as Notas Comerciais serão garantidas, ainda, por:
                                </Text>
                            </Text>
                            {garantiaData.map((data, index) => (
                                data?.gDoc2 && (
                                    <Text style={[styles.textJustify,
                                    {
                                        fontSize: 12, marginVertical: '0px',
                                        marginLeft: '68px', marginRight: '20px',

                                    }]}>
                                        {String.fromCharCode(97 + index)}){"   "}
                                        <Text wrap>
                                            {data?.gTipo}, devidamente descritos e caracterizados no anexo III em caráter irrevogável e irretratável, em favor do Titular (“Garantia Real”).
                                        </Text>
                                    </Text>
                                )
                            ))}
                            {/*Texto garantia real veiculo*/}
                            {veiculo && (
                                <View>
                                    <Text style={[styles.textJustify, {
                                        fontSize: 12,
                                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                                        marginTop: '20px'
                                    }]}>
                                        9.3{"  "}
                                        <Text>
                                            Fiel Depositário
                                        </Text>
                                    </Text>
                                    <Text style={[styles.textJustify, {
                                        fontSize: 12,
                                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                        marginTop: '20px',
                                    }]}>
                                        9.3.1{"   "}
                                        {fiel?.nome && (
                                            <Text>
                                                O Emissor, neste ato, nomeia o {fiel.nome} , com o CPF nº {fiel.fCpf} como fiel depositário do veículo, obrigando-se a bem guardá-lo e conservá-lo como se fosse
                                                seu, bem como, às suas expensas, e entregá-lo ao investidor a ou a quem este indicar, quando
                                                chamados a fazê-lo e conforme as determinações feitas pelo investidor referentes ao local,
                                                data e horário de entrega do Veículo, assumindo todas as responsabilidades e obrigações
                                                estabelecidas nos artigos 627 a 646 do Código Civil até o cumprimento integral de todas as
                                                Obrigações Garantidas.
                                            </Text>
                                        )}
                                    </Text>
                                    <Text style={[styles.textJustify, {
                                        fontSize: 12,
                                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                        marginTop: '20px',
                                    }]}>
                                        9.3.2{"   "}
                                        <Text>
                                            Em nenhuma hipótese o investidor ou seu eventual sucessor, será responsabilizado pela
                                            manutenção da integridade do Veículo e, portanto, eventuais danos, desgastes decorrentes do
                                            uso ou perda do Veículo são, única e exclusivamente, de responsabilidade do Emissor
                                        </Text>
                                    </Text>
                                    <Text style={[styles.textJustify, {
                                        fontSize: 12,
                                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                        marginTop: '20px'
                                    }]}>
                                        9.3.3{"   "}
                                        <Text>
                                            Em caso de perda, deterioração ou diminuição, parcial ou total, do valor do Veículo,
                                            compromete-se o emissor a reforçá-la/substituí-la sempre que solicitado pelo investidor, em
                                            até 15 (quinze) dias da data do evento que causou a diminuição do valor do Veículo ou da data
                                            da notificação recebida do investidor a esse respeito.
                                        </Text>
                                    </Text>
                                    <Text style={[styles.textJustify, {
                                        fontSize: 12,
                                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                        marginTop: '20px'
                                    }]}>
                                        9.3.4{"   "}
                                        <Text>
                                            Na hipótese de substituição do Veículo por qualquer motivo, o emissor deverá apresentar
                                            ao investidor, às suas expensas exclusivamente, a avaliação do novo bem, que deverá ser
                                            realizada por estabelecimento especializado, cujo laudo em papel timbrado deverá conter, no
                                            mínimo, as seguintes informações: (i) descrição das características principais do bem para sua
                                            completa identificação; (ii) valor atual do bem; (iii) estado de conservação do mesmo.
                                        </Text>
                                    </Text>
                                    <Text style={[styles.textJustify, {
                                        fontSize: 12,
                                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                        marginTop: '20px'
                                    }]}>
                                        9.3.5{"   "}
                                        <Text>
                                            Em caso de inadimplemento das Obrigações Garantidas, seja no seu vencimento
                                            ordinário ou em caso de declaração de vencimento antecipado, consolidar-se-á no investidor a
                                            propriedade plena do Veículo, podendo o investidor por si próprio ou representado pelo
                                            AGENTE DE GARANTIAS, sem prejuízo dos demais direitos previstos em lei,
                                            especialmente aqueles previstos no artigo 66-B da Lei 4.728/65 e no artigo 1.364 do Código
                                            Civil, de forma amigável e de boa fé, independentemente de avaliação, notificação judicial ou
                                            extrajudicial, leilão, hasta pública, ou qualquer outra medida judicial ou extrajudicial, alienar a
                                            terceiros o Veículo, no todo ou em parte, judicial ou extrajudicialmente, aplicando o preço no
                                            pagamento das Obrigações Garantidas e das despesas decorrentes da excussão desta garantia
                                        </Text>
                                    </Text>
                                    <Text style={[styles.textJustify, {
                                        fontSize: 12,
                                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                        marginTop: '20px'
                                    }]}>
                                        9.3.6{"   "}
                                        <Text>
                                            Os recursos apurados de acordo com os procedimentos de excussão da presente garantia
                                            na medida em que forem sendo recebidos, deverão ser imediatamente aplicados na
                                            amortização ou quitação do saldo devedor das Obrigações Garantidas. Caso os recursos
                                            apurados de acordo com os procedimentos de excussão não sejam suficientes para quitar
                                            simultaneamente todas as Obrigações Garantidas, tais recursos deverão ser imputados na
                                            seguinte ordem, de tal forma que, uma vez liquidados os valores referentes ao primeiro item,
                                            os recursos sejam alocados para o item imediatamente seguinte, e assim sucessivamente. Na
                                            hipótese de excussão da garantia objeto da NOTA COMERCIAL, e ocorrendo a alienação do
                                            Veículo, o produto obtido deverá ser entregue ao investidor, observada a seguinte ordem: (i)
                                            pagamento das despesas incorridas com a excussão das garantias; (ii) eventual processo
                                            judicial, inclusive custas processuais e honorários advocatícios e de peritos; (iii) multas e
                                            encargos moratórios de qualquer natureza; (iv ) o saldo, após deduzidos os valores do item
                                            anterior, se houver, deverá ser imputado no pagamento dos juros e encargos incidentes sobre
                                            os valores devidos em decorrência das Obrigações Garantidas; (v) o saldo, após deduzidos os
                                            valores dos itens anteriores, se houver, deverá ser imputado no pagamento do principal das
                                            Obrigações Garantidas; e (vi) o saldo, após deduzidos os valores dos itens anteriores, se
                                            houver, deverá ser restituído em até 3 (três) dias úteis ao emissor ou para terceiro garantidor
                                            quando este for o titular do Veículo.
                                        </Text>
                                    </Text>
                                    <Text style={[styles.textJustify, {
                                        fontSize: 12,
                                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                        marginTop: '20px'
                                    }]}>
                                        9.3.7{"   "}
                                        <Text>
                                            O emissor permanecerá responsável pelo saldo devedor das Obrigações Garantidas que
                                            não tiver sido pago, sem prejuízo dos acréscimos de juros, encargos moratórios e demais
                                            encargos incidentes sobre o saldo devedor das Obrigações Garantidas, declarando o emissor,
                                            neste ato, que tal saldo devedor será líquido e exigível pela via executiva.
                                        </Text>
                                    </Text>
                                    <Text style={[styles.textJustify, {
                                        fontSize: 12,
                                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                        marginTop: '20px'
                                    }]}>
                                        9.3.8{"   "}
                                        <Text>
                                            O emissor ou garantidor(es), quando aplicável, obriga(m)-se a praticar todos os atos e
                                            cooperar com o investidor em tudo que se fizer necessário ao cumprimento do disposto na
                                            Nota Comercial, inclusive no que se refere ao atendimento das exigências legais e
                                            regulamentares necessárias, se houver, à excussão da alienação fiduciária ora constituída.
                                        </Text>
                                    </Text>
                                    <Text style={[styles.textJustify, {
                                        fontSize: 12,
                                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                        marginTop: '20px'
                                    }]}>
                                        9.3.9{"   "}
                                        <Text>
                                            A substituição de Veículo por qualquer motivo está sujeita aos critérios de
                                            discricionariedade exclusivamente do investidor, ou do seu sucessor e deverá ser documentada
                                            por meio de aditamento da Nota Comercial bem como manutenção do Gravame pelo
                                            AGENTE DE GARANTIAS.
                                        </Text>
                                    </Text>
                                    <Text style={[styles.textJustify, {
                                        fontSize: 12,
                                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                        marginTop: '20px',
                                    }]}>
                                        {fiel?.nome && (
                                            <Text>
                                                Fica certo e ajustado o caráter não excludente, mas, se e quando aplicável, cumulativo entre si, da Garantia Real, nos termos deste Termo Constitutivo, podendo o Titular executar ou excutir todas ou cada uma delas indiscriminadamente, em qualquer ordem, para os fins de amortizar ou quitar com as obrigações decorrentes do presente Termo Constitutivo.
                                            </Text>
                                        )}
                                    </Text>
                                    <Text style={[styles.textJustify, {
                                        fontSize: 12,
                                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                        marginTop: '20px',
                                    }]}>
                                        {fiel?.nome && (
                                            <Text>
                                                E, por estarem assim justas e contratadas, as Partes celebram o presente Termo de Emissão,
                                                por meio de assinaturas digitais com certificação no padrão da Infraestrutura de Chaves
                                                Públicas Brasileira (ICP-Brasil). Uma vez assinada digitalmente pelas Partes, o presente
                                                Termo de Emissão devidamente assinado ficará disponível na plataforma digital, ficando cada
                                                Parte responsável por obter uma ou mais vias e mantê-la(s) em seus arquivos e registros
                                            </Text>
                                        )}
                                    </Text>
                                    <Text style={[styles.textJustify, {
                                        fontSize: 12,
                                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                        marginTop: '20px',
                                    }]}>
                                        {fiel?.nome && (
                                            <Text>
                                                As Partes signatárias reconhecem a forma de contratação por meios eletrônicos, digitais e
                                                informáticos como válida e plenamente eficaz, constituindo título executivo extrajudicial para todos os fins de direito.
                                            </Text>
                                        )}
                                    </Text>
                                </View>
                            )
                            }
                            {/*Texto garantia real Imovel*/}
                            {imovel && (
                                <View>
                                    <Text style={[styles.textJustify, {
                                        fontSize: 12,
                                        marginVertical: '0px', marginLeft: '40px', marginRight: '20px',
                                        marginTop: '20px'
                                    }]}>
                                        9.3{"  "}
                                        <Text>
                                            Fiel Depositário
                                        </Text>
                                    </Text>
                                    <Text style={[styles.textJustify, {
                                        fontSize: 12,
                                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                        marginTop: '20px',
                                    }]}>
                                        9.3.1{"   "}
                                        {fiel?.nome && (
                                            <Text>
                                                O Emissor, neste ato, nomeia a {fiel.nome}, com CPF nº {fiel.fCpf} como fiel depositário do Imóvel, obrigando-se a bem guardá-lo e conservá-lo como se fosse seu, bem como, às suas expensas, e entregá-lo ao Investidor a ou a quem este indicar, quando chamados a fazê-lo e conforme as determinações feitas pelo Investidor referentes ao local, data e horário de entrega do imóvel, assumindo todas as responsabilidades e obrigações estabelecidas até o cumprimento integral de todas as Obrigações Garantidas.
                                            </Text>
                                        )}
                                    </Text>
                                    <Text style={[styles.textJustify, {
                                        fontSize: 12,
                                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                        marginTop: '20px',
                                    }]}>
                                        9.3.2{"   "}
                                        {fiel?.nome && (
                                            <Text>
                                                Em nenhuma hipótese o investidor ou seu eventual sucessor, será responsabilizado pela manutenção da integridade do imóvel e, portanto, eventuais danos, desgastes decorrentes do uso, são única e exclusivamente, de responsabilidade do Emissor.
                                            </Text>
                                        )}
                                    </Text>
                                    <Text style={[styles.textJustify, {
                                        fontSize: 12,
                                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                        marginTop: '20px',
                                    }]}>
                                        9.3.3{"   "}
                                        {fiel?.nome && (
                                            <Text>
                                                Em caso de deterioração ou diminuição, parcial ou total, do valor do imóvel, o Emissor compromete-se a reforçar ou substituir a garantia sempre que solicitado pelo Investidor, no prazo de até 15 (quinze) dias a contar da data do evento que causou a diminuição do valor do imóvel ou da data da notificação recebida do Investidor a esse respeito.
                                            </Text>
                                        )}
                                    </Text>
                                    <Text style={[styles.textJustify, {
                                        fontSize: 12,
                                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                        marginTop: '20px',
                                    }]}>
                                        9.3.4{"   "}
                                        {fiel?.nome && (
                                            <Text>
                                                Em caso de inadimplemento das Obrigações Garantidas, seja no seu vencimento ordinário ou em caso de declaração de vencimento antecipado, consolidar-se-á no investidor a propriedade plena do imóvel, podendo o investidor, por si próprio ou representado pelo AGENTE DE GARANTIAS, sem prejuízo dos demais direitos previstos em lei, especialmente aqueles previstos no artigo 66-B da Lei 4.728/65 e no artigo 1.364 do Código Civil, de forma amigável e de boa-fé, independentemente de avaliação, notificação judicial ou extrajudicial, leilão, hasta pública, ou qualquer outra medida judicial ou extrajudicial, alienar a terceiros o imóvel, no todo ou em parte, judicial ou extrajudicialmente, aplicando o preço no pagamento das Obrigações Garantidas e das despesas decorrentes da excussão desta garantia.
                                            </Text>
                                        )}
                                    </Text>
                                    <Text style={[styles.textJustify, {
                                        fontSize: 12,
                                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                        marginTop: '20px',
                                    }]}>
                                        9.3.5{"   "}
                                        {fiel?.nome && (
                                            <Text>
                                                Os recursos apurados de acordo com os procedimentos de excussão da presente garantia, na medida em que forem sendo recebidos, deverão ser imediatamente aplicados na amortização ou quitação do saldo devedor das Obrigações Garantidas. Caso os recursos apurados não sejam suficientes para quitar simultaneamente todas as Obrigações Garantidas, tais recursos deverão ser imputados na seguinte ordem, de tal forma que, uma vez liquidados os valores referentes ao primeiro item, os recursos sejam alocados para o item imediatamente seguinte, e assim sucessivamente. Na hipótese de excussão da garantia objeto da nota comercial e ocorrendo a alienação do imóvel, o produto obtido deverá ser entregue ao investidor, observada a seguinte ordem: (i) pagamento das despesas incorridas com a excussão das garantias; (ii) eventual processo judicial, inclusive custas processuais e honorários advocatícios e de peritos; (iii) multas e encargos moratórios de qualquer natureza; (iv) o saldo, após deduzidos os valores do item anterior, se houver, deverá ser imputado no pagamento dos juros e encargos incidentes sobre os valores devidos em decorrência das Obrigações Garantidas; (v) o saldo, após deduzidos os valores dos itens anteriores, se houver, deverá ser imputado no pagamento do principal das Obrigações Garantidas; e (vi) o saldo, após deduzidos os valores dos itens anteriores, se houver, deverá ser restituído em até 3 (três) dias úteis ao emissor ou para terceiro garantidor quando este for o titular do imóvel.
                                            </Text>
                                        )}
                                    </Text>
                                    <Text style={[styles.textJustify, {
                                        fontSize: 12,
                                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                        marginTop: '20px',
                                    }]}>
                                        9.3.6{"   "}
                                        {fiel?.nome && (
                                            <Text>
                                                O emissor permanecerá responsável pelo saldo devedor das Obrigações Garantidas que não tiver sido pago, sem prejuízo dos acréscimos de juros, encargos moratórios e demais encargos incidentes sobre o saldo devedor das Obrigações Garantidas, declarando o emissor, neste ato, que tal saldo devedor será líquido e exigível pela via executiva.
                                            </Text>
                                        )}
                                    </Text>
                                    <Text style={[styles.textJustify, {
                                        fontSize: 12,
                                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                        marginTop: '20px',
                                    }]}>
                                        9.3.7{"   "}
                                        {fiel?.nome && (
                                            <Text>
                                                A substituição do imóvel por qualquer motivo está sujeita aos critérios de discricionariedade exclusivamente do investidor, ou do seu sucessor, e deverá ser documentada por meio de aditamento à nota comercial, bem como a manutenção da alienação em cartório.
                                            </Text>
                                        )}
                                    </Text>
                                    <Text style={[styles.textJustify, {
                                        fontSize: 12,
                                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                        marginTop: '20px',
                                    }]}>
                                        {fiel?.nome && (
                                            <Text>
                                                Fica certo e ajustado o caráter não excludente, mas, se e quando aplicável, cumulativo entre si, da Garantia Real, nos termos deste Termo Constitutivo, podendo o Titular executar ou excutir todas ou cada uma delas indiscriminadamente, em qualquer ordem, para os fins de amortizar ou quitar com as obrigações decorrentes do presente Termo Constitutivo.
                                            </Text>
                                        )}
                                    </Text>
                                    <Text style={[styles.textJustify, {
                                        fontSize: 12,
                                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                        marginTop: '20px',
                                    }]}>
                                        {fiel?.nome && (
                                            <Text>
                                                E, por estarem assim justas e contratadas, as Partes celebram o presente Termo de Emissão, por meio de assinaturas digitais com certificação no padrão da Infraestrutura de Chaves Públicas Brasileira (ICP-Brasil). Uma vez assinada digitalmente pelas Partes, o presente Termo de Emissão devidamente assinado ficará disponível na plataforma digital, ficando cada Parte responsável por obter uma ou mais vias e mantê-la(s) em seus arquivos e registros
                                            </Text>
                                        )}
                                    </Text>
                                    <Text style={[styles.textJustify, {
                                        fontSize: 12,
                                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                        marginTop: '20px',
                                    }]}>
                                        {fiel?.nome && (
                                            <Text style={styles.boldText}>
                                                A assinatura do ESCRITURADOR no presente Termo Constitutivo não implica na precificação dos títulos escriturados, os valores são registrados pelo valor de face do título no ato da Escrituração, a garantia de pagamento e declaração para o Emissor, Investidor ou para terceiros da posição escriturada para efeito de constituição de garantias para operações de crédito. Cabe ao ESCRITURADOR informar somente para o contratante dos serviços, a posição de títulos escriturados conforme clausula prevista no “INSTRUMENTO PARTICULAR DE PRESTAÇÃO DE SERVIÇOS DE ESCRITURAÇÃO”
                                            </Text>
                                        )}
                                    </Text>
                                    <Text style={[styles.textJustify, {
                                        fontSize: 12,
                                        marginVertical: '0px', marginLeft: '60px', marginRight: '20px',
                                        marginTop: '20px',
                                    }]}>
                                        {fiel?.nome && (
                                            <Text>
                                                As Partes signatárias reconhecem a forma de contratação por meios eletrônicos, digitais e informáticos como válida e plenamente eficaz, constituindo título executivo extrajudicial para todos os fins de direito
                                            </Text>
                                        )}
                                    </Text>
                                </View>
                            )
                            }
                        </>
                    )}
                </Page> : null
                {/*Assinaturas*/}
                <Page style={styles.page}>
                    <View style={[styles.section]}>
                        <Text style={[styles.textCenter, {
                            fontSize: 12,
                            marginVertical: '0px', marginHorizontal: '20px', marginTop: '20px', marginBottom: '10px'
                        }]}>
                            {emissor.cidade}/{emissor.estado}, {dataEmissao}
                        </Text>
                        <Text style={[styles.text1, { marginTop: 30, marginHorizontal: '20px' }]}>
                            {""}
                        </Text>
                        <Text style={[styles.text2, { marginTop: 20, marginHorizontal: '20px', marginBottom: '10px' }]}>
                            {emissor.razaoSocial}{'\n'}
                        </Text>
                        <Text style={[styles.text2, { marginTop: 20, marginHorizontal: '20px', marginBottom: '10px' }]}>
                            {investidor.razaoSocial}{'\n'}
                        </Text>
                        {/*<Text style={[styles.text1, { marginHorizontal: '20px', marginTop: '0px' }]}>
                            EMITENTE
                        </Text>*/}
                        {avalistas.map((avalista, index) => (
                            <View style={[styles.view2, { marginBottom: 5 }]} key={index}>
                                <Text style={[styles.text2, { marginTop: 10, marginHorizontal: '20px' }]}>{avalista.aNome}</Text>
                                <Text style={{ marginHorizontal: '20px', marginTop: '10px' }}>AVALISTA</Text>
                            </View>
                        ))}
                        {garantiaData && garantiaData?.length > 0 && garantiaData[0]?.gTipo && fiel?.nome && (
                            <View style={[styles.view2, { marginBottom: 5 }]}>
                                <Text style={[styles.text2, { marginTop: 10, marginHorizontal: '20px' }]}>{fiel.nome}</Text>
                                <Text style={{ marginHorizontal: '20px', marginTop: '10px' }}>FIEL DEPOSITÁRIO</Text>
                            </View>
                        )}
                        <Text style={[styles.text2, { marginTop: 20, marginHorizontal: '20px', marginBottom: '10px' }]}>
                            OSLO CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.
                            ESCRITURADORA
                        </Text>
                    </View>
                </Page>
                <Page style={styles.page}>
                    <View style={styles.section}>
                        <Text style={[styles.textBold, { marginTop: '2px', textAlign: 'center', textDecoration: 'underline', fontSize: 12, marginBottom: '10px' }]}>
                            ANEXO I – BOLETIM DE SUBSCRIÇÃO DAS NOTAS COMERCIAIS
                        </Text>
                        <Text style={[styles.textBold, { marginTop: '2px', fontSize: 12, textAlign: 'center' }]}>
                            BOLETIM DE SUBSCRIÇÃO DA {dados.emissao} EMISSÃO DE NOTAS COMERCIAIS,{garantiaData && garantiaData.length > 0 && garantiaData[0]?.gTipo ? 'COM GARANTIA REAL' : 'SEM GARANTIA REAL'}, EM SÉRIE ÚNICA, PARA DISTRIBUIÇÃO PRIVADA COM ESFORÇOS RESTRITOS, DA {emissor.razaoSocial.toUpperCase()}
                        </Text>
                        <View style={[styles.tableContainer]}>
                            <View style={[styles.tableContainer, { border: '0px' }]}>
                                <Text style={[styles.text1, { margin: '5px' }]}>
                                    Emissão, pela {emissor.razaoSocial}, empresa individual de responsabilidade limitada, com sede em {emissor.rua}, {emissor.numero}, {emissor.complemento ? emissor.complemento + ',' : ''} {emissor.bairro}, {emissor.cidade} do estado de {emissor.estado}, CEP {emissor.cep}, inscrita no CNPJ/ME sob nº {emissor.cnpj}.{'\n'}
                                    de 1 (um) notas comerciais, com valor nominal unitário de {allInfoArray[13]}, vencimento em {dataDoVencimento}, parcelada em {parcelas} parcela(s) apresentada(s) no plano de pagamento em anexo, da sua [{dados.emissao}] emissão. (“Notas Comerciais “e “Emissão”)
                                </Text>
                                <Text style={[styles.text1, { borderTopWidth: '1px', paddingVertical: '5px', borderBottomWidth: '1px', backgroundColor: '#d9d9d9', textAlign: 'center', fontSize: 10, fontFamily: 'Helvetica-Bold' }]}>
                                    QUALIFICAÇÃO SUBSCRITOR E SEU REPRESENTANTE
                                </Text>
                                <View style={[styles.row]}>
                                    <Text style={[styles.cell3, { border: '0px', borderBottomWidth: '1px', borderRightWidth: '1px', width: '25%', fontSize: 10 }]}>
                                        Razão Social:
                                    </Text>
                                    <Text style={[styles.cell3, { border: '0px', borderRightWidth: '1px', width: '50%', fontSize: '10px', borderBottomWidth: '1px' }]}>
                                        {investidor.razaoSocial}
                                    </Text>
                                    <Text style={[styles.cell3, { border: '0px', borderRightWidth: '1px', width: '25%', fontSize: '10px', borderBottomWidth: '1px' }]}>
                                        CNPJ:
                                    </Text>
                                    <Text style={[styles.cell3, { border: '0px', width: '50%', fontSize: '10px', borderBottomWidth: '1px' }]}>
                                        {formatCnpj(investidor.cnpj)}
                                    </Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={[styles.cell3, { border: '0px', borderBottomWidth: '1px', borderRightWidth: '1px', width: '25%' }]}>Endereço:
                                    </Text>
                                    <Text style={[styles.cell3, { border: '0px', borderRightWidth: '1px', width: '50%', fontSize: '10px', borderBottomWidth: '1px' }]}>
                                        {investidor.rua.trim()}, {investidor.cidade}, {investidor.estado}
                                    </Text>
                                    <Text style={[styles.cell3, { border: '0px', borderRightWidth: '1px', width: '25%', borderBottomWidth: '1px' }]}>
                                        Telefone:
                                    </Text>
                                    <Text style={[styles.cell3, { border: '0px', width: '50%', borderBottomWidth: '1px' }]}>
                                        {investidor.telefone}
                                    </Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={[styles.cell3, { border: '0px', borderRightWidth: '1px', width: '25%' }]}>
                                        Representado por:
                                    </Text>
                                    <Text style={[styles.cell3, { border: '0px', borderRightWidth: '1px', width: '50%', fontSize: '10px' }]}>
                                        {investidor.representantes.map((representante, index) => (
                                            <React.Fragment key={index}>
                                                {representante.nome}
                                                {index < investidor.representantes.length - 1 && '\n'}
                                            </React.Fragment>
                                        ))}
                                    </Text>
                                    <Text style={[styles.cell3, { border: '0px', borderRightWidth: '1px', width: '25%' }]}>
                                        País:
                                    </Text>
                                    <Text style={[styles.cell3, { border: '0px', width: '50%' }]}>
                                        Brasil
                                    </Text>
                                </View>
                                <Text style={[styles.text1, { borderTopWidth: '1px', padding: '10px', backgroundColor: '#d9d9d9', textAlign: 'center', fontSize: 10, fontFamily: 'Helvetica-Bold' }]}>
                                    NOTAS COMERCIAIS SUBSCRITAS
                                </Text>
                                <View style={[styles.row, { backgroundColor: '#d9d9d9' }]}>

                                    <Text style={[styles.cell1, { borderTopWidth: '1px', borderBottomWidth: '1px', fontSize: '10px', borderLeftWidth: '0px' }]}>
                                        QUANTIDADE
                                    </Text>
                                    <Text style={[styles.cell1, { borderTopWidth: '1px', borderBottomWidth: '1px' }]}>
                                        PREÇO UNITÁRIO
                                    </Text>
                                    <Text style={[styles.cell1, { borderTopWidth: '1px', borderBottomWidth: '1px' }]}>
                                        VALOR EM R$
                                    </Text>
                                </View>
                                <View style={[styles.row]}>
                                    <Text style={[styles.cell1, { fontSize: '10px', borderLeftWidth: '0px' }]}>
                                        1
                                    </Text>
                                    <Text style={[styles.cell1, {}]}>
                                        {allInfoArray[13]}
                                    </Text>
                                    <Text style={[styles.cell1, {}]}>
                                        {allInfoArray[13]}
                                    </Text>
                                </View>
                                <View style={[styles.text3]}>
                                    <Text style={[{
                                        borderTopWidth: '1px', textAlign: 'center', borderRightWidth: '0px', borderLeftWidth: '0px', marginBottom: '0px', verticalAlign: 'sub'
                                    }]}>
                                        A rentabilidade de cada nota comercial se inicia a partir da data da efetiva integralização.
                                    </Text>
                                </View>
                            </View>
                            <Text style={[styles.text1, { borderTopWidth: '1px', padding: '2px', backgroundColor: '#d9d9d9', textAlign: 'center', fontSize: 10, fontFamily: 'Helvetica-Bold', borderBottomWidth: '1px' }]}>
                                FORMA DE SUBSCRIÇÃO E INTEGRALIZAÇÃO
                            </Text>
                            <View style={styles.row}>
                                <Text style={[styles.cell, { borderRightWidth: '1px', borderBottomWidth: '1px', width: '50%' }]}>
                                    MEIO DE INTEGRALIZAÇÃO
                                </Text>
                                <Text style={[styles.cell, { borderBottomWidth: '1px', width: '50%' }]}>
                                    DATA DE INTEGRALIZAÇÃO
                                </Text>
                            </View>
                            <View style={[styles.row]}>
                                <Text style={[styles.cell, { borderRightWidth: '1px', borderBottomWidth: '1px', width: '50%' }]}>
                                    {emissor.meioPagamento}
                                </Text>
                                <Text style={[styles.cell, { borderBottomWidth: '1px', width: '50%' }]}>
                                    A Efetiva integralização deverá ser realizada no máximo em 30 (trinta) dias corridos contados da data da assinatura deste Boletim de Subscrição.
                                </Text>
                            </View>
                            <Text style={[styles.text1, { borderTopWidth: '1px', padding: '5px', backgroundColor: '#d9d9d9', textAlign: 'center', fontSize: 10, fontFamily: 'Helvetica-Bold' }]}>
                                CONTA BANCÁRIA DA EMITENTE
                            </Text>
                            <View style={styles.row}>
                                <Text style={[styles.cell, { borderBottom: '1px', backgroundColor: '#d9d9d9', borderTop: '1px', borderRight: '1px', width: '50%' }]}>
                                    BANCO
                                </Text>
                                <Text style={[styles.cell, { borderBottom: '1px', backgroundColor: '#d9d9d9', borderTop: '1px', borderRight: '1px', width: '30%' }]}>
                                    Nº do Banco
                                </Text>
                                <Text style={[styles.cell, { borderBottom: '1px', backgroundColor: '#d9d9d9', borderTop: '1px', borderRight: '1px', width: '30%' }]}>
                                    Nº Agência
                                </Text>
                                <Text style={[styles.cell, { borderBottom: '1px', backgroundColor: '#d9d9d9', borderTop: '1px', borderRight: '1px' }]}>
                                    Nº da conta
                                </Text>
                                <Text style={[styles.cell, { borderBottom: '1px', backgroundColor: '#d9d9d9', borderTop: '1px' }]}>
                                    Valor
                                </Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={[styles.cell, { borderRight: '1px', width: '50%' }]}>
                                    {emissor.banco}
                                </Text>
                                <Text style={[styles.cell, { borderRight: '1px', width: '30%' }]}>
                                    {emissor.bankNumber}
                                </Text>
                                <Text style={[styles.cell, { borderRight: '1px', width: '30%' }]}>
                                    {emissor.agency}
                                </Text>
                                <Text style={[styles.cell, { borderRight: '1px' }]}>
                                    {emissor.conta}
                                </Text>
                                <Text style={[styles.cell, {}]}>
                                    {allInfoArray[5]}
                                </Text>
                            </View>
                            <Text style={[styles.text1, { borderTopWidth: '1px', padding: '2px', backgroundColor: '#d9d9d9', textAlign: 'center', fontSize: 10, fontFamily: 'Helvetica-Bold' }]}>
                                RESPONSÁVEL PELA ESCRITURAÇÃO
                            </Text>
                            <View style={styles.row}>
                                <Text style={[styles.cell, { fontSize: 9, borderTop: '1px', width: '70%' }]}>
                                    OSLO CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A
                                </Text>

                                <Text style={[styles.cell, { fontSize: 9, borderTop: '1px', borderLeft: '1px', width: '30%' }]}>
                                    CNPJ: 13.673.855/0001-25
                                </Text>
                            </View>
                            <Text style={[styles.text1, { borderTopWidth: '1px', padding: '2px', backgroundColor: '#d9d9d9', textAlign: 'center', fontSize: 10, fontFamily: 'Helvetica-Bold' }]}>
                                RESPONSÁVEL PELA ESTRUTURAÇÃO DA NOTA COMERCIAL
                            </Text>
                            <View style={styles.row}>
                                <Text style={[styles.cell, { borderBottom: '1px', backgroundColor: '#d9d9d9', borderTop: '1px', borderRight: '1px', width: '50%' }]}>
                                    RAZÃO SOCIAL
                                </Text>
                                <Text style={[styles.cell, { borderBottom: '1px', backgroundColor: '#d9d9d9', borderTop: '1px', borderRight: '1px', width: '30%' }]}>
                                    Nº do Banco
                                </Text>
                                <Text style={[styles.cell, { borderBottom: '1px', backgroundColor: '#d9d9d9', borderTop: '1px', borderRight: '1px', width: '30%' }]}>
                                    Nº Agência
                                </Text>
                                <Text style={[styles.cell, { borderBottom: '1px', backgroundColor: '#d9d9d9', borderTop: '1px', borderRight: '1px' }]}>
                                    Nº da conta
                                </Text>
                                <Text style={[styles.cell, { borderBottom: '1px', backgroundColor: '#d9d9d9', borderTop: '1px' }]}>
                                    Valor
                                </Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={[styles.cell, { borderRight: '1px', width: '50%' }]}>
                                    CRED2CARDS LTDA
                                </Text>
                                <Text style={[styles.cell, { borderRight: '1px', width: '30%' }]}>
                                    077
                                </Text>
                                <Text style={[styles.cell, { borderRight: '1px', width: '30%' }]}>
                                    0001
                                </Text>
                                <Text style={[styles.cell, { borderRight: '1px' }]}>
                                    23203346-3
                                </Text>
                                <Text style={[styles.cell, {}]}>
                                    {valorC3.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                </Text>
                            </View>
                            <Text style={[styles.text1, { borderTopWidth: '1px', padding: '10px', textAlign: 'center', fontSize: 10 }]}>
                                Declaro para os devidos fins que estou de acordo com as condições expressas no presente Boletim de Subscrição, bem como declaro ter obtido exemplar do Termo Constitutivo.

                            </Text>
                        </View>
                        <Text style={[styles.text1, { padding: '5px', textAlign: 'center', fontSize: 10, paddingTop: '5px' }]}>
                            {emissor.cidade}, {dataEmissao}
                        </Text>
                        <Text style={[styles.text2, { padding: '5px', textAlign: 'center', fontSize: 10, marginTop: '0px' }]}>
                            Assinatura do Representante Legal do Subscritor
                        </Text>
                    </View>
                </Page>
                {/*Plano de Pagamento*/}
                {chunkedCalcData.map((chunk, pageIndex) => (
                    <Page style={styles.page}>
                        <View style={styles.section}>
                            {isFirstPage && (
                                <View>
                                    <Text style={[styles.textBold, { textAlign: 'center', textDecoration: 'underline', marginBottom: '20px', fontSize: 12 }]}>
                                        ANEXO II – PLANO DE PAGAMENTO
                                    </Text>
                                    <View style={styles.downTable}>
                                        <View style={styles.tr}>
                                            <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '70px' }]}>Período</Text>
                                            <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '82px' }]}>Vencimento</Text>
                                            <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '90px' }]}>Parcela</Text>
                                            <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '91px' }]}>Amortização</Text>
                                            <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '88px' }]}>Juros</Text>
                                            <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '100px' }]}>Saldo Devedor</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tr}>
                                        <Text style={[styles.centralizado, { borderLeftWidth: '1px', borderRightWidth: '1px', borderRightColor: 'black', width: '71px' }]}>0</Text>
                                        <Text style={[styles.centralizado, { borderRightWidth: '1px', borderRightColor: 'black', width: '82px' }]}>
                                            {now.getDate().toString().padStart(2, '0')}/{(now.getMonth() + 1).toString().padStart(2, '0')}/{now.getFullYear()}
                                        </Text>
                                        <Text style={[styles.alinhamentoDireitaTd, { width: '90px' }]}>0</Text>
                                        <Text style={[styles.alinhamentoDireitaTd, { width: '91px' }]}>0</Text>
                                        <Text style={[styles.alinhamentoDireitaTd, { width: '88px' }]}>0</Text>
                                        <Text style={[styles.alinhamentoDireitaTd, { width: '100px' }]}>{allInfoArray[13]}</Text>
                                    </View>

                                </View>
                            )}
                            {chunk.map((data, index) => (
                                <View key={index} style={[styles.tr, { borderTopWidth: "1px" }]}>
                                    <Text style={[styles.centralizado, { borderLeftWidth: '1px', borderRightWidth: '1px', borderRightColor: 'black', width: '71px' }]}>{data[0]}</Text>
                                    <Text style={[styles.centralizado, { borderRightWidth: '1px', borderRightColor: 'black', width: '82px' }]}>
                                        {data[1] < 10 ? `0${data[1]}` : data[1]}/{data[2] === '00' ? 12 : data[2]}/{data[3]}
                                    </Text>
                                    <Text style={[styles.alinhamentoDireitaTd, { width: '90px' }]}>
                                        {data[4].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                    </Text>
                                    <Text style={[styles.alinhamentoDireitaTd, { width: '91px' }]}>
                                        {data[5].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                    </Text>
                                    <Text style={[styles.alinhamentoDireitaTd, { width: '88px' }]}>
                                        {data[6].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                    </Text>
                                    <Text style={[styles.alinhamentoDireitaTd, { width: '100px' }]}>
                                        {data[7].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                    </Text>
                                </View>
                            ))}
                        </View>
                        {isFirstPage && (pageIndex === 0 ? (isFirstPage = false) : null)}
                    </Page>
                ))}
                {/*Garantia*/}
                {garantiaData && garantiaData?.length > 0 && garantiaData[0]?.gTipo ?
                    <Page style={styles.page}>
                        <Text style={[styles.textBold, { textAlign: 'center', textDecoration: 'underline', marginBottom: '20px', fontSize: 12 }]}>
                            ANEXO III GARANTIA REAL
                        </Text>
                        <View>
                            {garantiaData.map((data, index) => (
                                data?.gDoc2 && (
                                    <div>
                                        <View style={styles.downTable}>
                                            <View style={styles.tr}>
                                                <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '20%' }]}>Tipo</Text>
                                                <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '20%' }]}>Quantidade</Text>
                                                <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '20%' }]}>Natureza</Text>
                                                <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '20%' }]}>CPF/CNPJ</Text>
                                                <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '20%' }]}>Proprietário</Text>
                                            </View>
                                        </View>
                                        <View style={styles.tr}>
                                            <Text style={[styles.centralizado, { borderLeftWidth: '1px', borderRightWidth: '1px', borderRightColor: 'black', width: '20%' }]}>{data?.gTipo}</Text>
                                            <Text style={[styles.centralizado, { borderRightWidth: '1px', borderRightColor: 'black', width: '20%' }]}>{data?.gQtd}</Text>
                                            <Text style={[styles.centralizado, { borderRightWidth: '1px', borderRightColor: 'black', width: '20%' }]}>{data?.gNat}</Text>
                                            <Text style={[styles.centralizado, { borderRightWidth: '1px', borderRightColor: 'black', width: '20%' }]}>{data?.gCPF}</Text>
                                            <Text style={[styles.centralizado, { borderRightWidth: '1px', borderRightColor: 'black', width: '20%' }]}>{data?.gProp}</Text>
                                        </View>
                                        <Text style={[styles.text1, { textAlign: 'justify', marginRight: '20px', fontSize: 12, marginTop: '20px' }]}>
                                            Descrição:
                                        </Text>
                                        <Text style={[styles.text1, { textAlign: 'justify', marginRight: '20px', fontSize: 12, marginTop: '20px' }]}>
                                            {data?.gDesc}
                                        </Text>
                                        <Image src={data?.gDoc2} style={{ width: '80%', paddingBottom: '5%', paddingTop: '5%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                                    </div>
                                )
                            ))}
                        </View>
                    </Page> : null}
            </Document>
        </PDFViewer>
    )
}

export default OsloSemGarantia;