import React, { useState } from "react";
import { GrDocumentUpdate } from "react-icons/gr";
import '../styles/Investidors.css';

function InvestidorModal({ selectedEmissor, handleSave, onClose }) {

    return (

        <div>
            <div className="modal">
                <div className="modal-content">
                    <button onClick={onClose} className="modalClose">X</button>
                    <h3 className="per1">Detalhes do Investidor Selecionado:</h3>

                    <div className="investidorContainer">
                        <div className="investidorField">
                            <div className="invesP">Razão Social:</div>
                            <div className="invesP1">{selectedEmissor?.razaoSocial}</div>
                        </div>
                        <div className="investidorField">
                            <div className="invesP">CNPJ:</div>
                            <div className="invesP1">{selectedEmissor?.cnpj}</div>
                        </div>
                        <div className="investidorField">
                            <div className="invesP">Representante:</div>
                            <div className="invesP1">{selectedEmissor?.representantes[0].nome}</div>
                        </div>
                        <div className="investidorField">
                            <div className="invesP">Endereço:</div>
                            <div className="invesP1">
                                CEP: {selectedEmissor?.cep},<br />
                                Rua: {selectedEmissor?.rua},<br />
                                Bairro: {selectedEmissor?.bairro},<br />
                                Cidade: {selectedEmissor?.cidade},<br />
                                UF: {selectedEmissor?.estado}
                            </div>
                        </div>
                    </div>

                    <div style={{ display: 'flex', width: '100%', justifyContent: 'start' }}>
                        <button onClick={() => { handleSave(selectedEmissor) }} className="newButtonInves">Selecionar Investidor para Nota Comercial</button>
                    </div>

                </div>
            </div>
        </div >
    )
}

export default InvestidorModal;
