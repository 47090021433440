import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { formatCnpj, formatCpf, formatDocument, formatRg } from "../../utils/Tools";
import { formatBankAccount, formatarTelefone } from "../../pages/Investidor";
import axios from "axios";
import ReactInputMask from "react-input-mask";
import DevedorSolidario from "../../components/DevedorSolidario";
import '../../styles/Emissor.css';


const EmissorModal = ({ isOpen, onClose, emissorData }) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const emissor = emissorData
    console.log(emissorData.razaoSocial,'emissor')
    console.log(emissorData,'emissorData')
    const storedData = JSON.parse(localStorage.getItem('notaComercial'));

    const [dadosBancarios, setDadosBancarios] = useState(storedData ? storedData.dadosBancarios : '');
    const [conta, setConta] = useState(emissor ? emissor.conta : '');
    const [agency, setAgency] = useState(emissor ? emissor.agency : '');
    const [banks, setBanks] = useState([]);
    const [selectedBank, setSelectedBank] = useState(emissor ? emissor.banco : '');
    const [bankNumber, setBankNumber] = useState(emissor ? emissor.bankNumber : '');
    const [devedor, setDevedor] = useState(true);
    const [fiel, setFiel] = useState(false);
    const [avalista, setAvalista] = useState(false);
    const [dRazao, setDRazao] = useState(emissor ? emissor.razaoSocial : '');
    const [dCnpj, setDCnpj] = useState(emissor ? emissor.cnpj : '');
    const [dCep, setDCep] = useState(emissor ? emissor.cep : '');
    const [dRua, setDRua] = useState(emissor ? emissor.rua : '');
    const [dCidade, setDCidade] = useState(emissor ? emissor.cidade : '');
    const [dEstado, setDEstado] = useState(emissor ? emissor.estado : '');
    const [dNumero, setDNumero] = useState(emissor ? emissor.numero : '');
    const [dBairro, setDBairro] = useState(emissor ? emissor.bairro : '');
    const [dComplemento, setDComplemento] = useState(emissor ? emissor.complemento : '');
    const [dEmail, setDEmail] = useState(emissor ? emissor.email : '');
    const [representante, setRepresentante] = useState(emissor ? emissor.representante : '');
    const [dCpf, setDCpf] = useState(emissor ? emissor.cpf : '');
    const [cargo, setCargo] = useState(emissor ? emissor.cargo : '');

    const [dAdress, setDAdress] = useState('');

    const [fName, setFName] = useState(emissor ? emissor.fielDepositario.nome : '');
    const [fNacionalidade, setFNacionalidade] = useState(emissor ? emissor.fielDepositario.fNacionalidade : '');
    const [fOcupacao, setFOcupacao] = useState(emissor ? emissor.fielDepositario.ocupacao : '');
    const [fCep, setFCep] = useState(emissor ? emissor.fielDepositario.fCep : '');
    const [fRua, setFRua] = useState(emissor ? emissor.fielDepositario.fRua : '');
    const [fCidade, setFCidade] = useState(emissor ? emissor.fielDepositario.fCidade : '');
    const [fEstado, setFEstado] = useState(emissor ? emissor.fielDepositario.fEstado : '');
    const [fNumero, setFNumero] = useState(emissor ? emissor.fielDepositario.fNumero : '');
    const [fComplemento, setFComplemento] = useState(emissor ? emissor.fielDepositario.fComplemento : '');
    const [fCpf, setFCpf] = useState(emissor ? emissor.fielDepositario.fCpf : '');
    const [fRg, setFRg] = useState(emissor ? emissor.fielDepositario.fRg : '');
    const [fEmail, setFEmail] = useState(emissor ? emissor.fielDepositario.fEmail : '');
    const [avalistas, setAvalistas] = useState(emissor ? emissor.avalistas : [{ aNome: '', aCpf: '', aRg: '', aEmail: '', aCep: '', aRua: '', aCidade: '', aEstado: '', aNumero: '', aComplemento: '', aProfissao: '', aNacionalidade: 'Brasileiro(a)', aEstadoCivil: 'solteiro(a)', aDataNascimento: '' }]);
    const [meioPagamento, setMeioPagamento] = useState('[X] Transferência Eletrônica Disponível (TED)');
    const [erroValidacao, setErroValidacao] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [solidario, setSolidario] = useState(false);
    const [devedorInfo, setDevedorInfo] = useState({});

    const [editedData, setEditedData] = useState({ ...emissorData });
    const toggleSolidario = () => {
        setSolidario(!solidario);
    }
    useEffect(() => {
        setEditedData({ ...emissorData });
    }, [emissorData]);

    useEffect(() => {
        fetch('https://brasilapi.com.br/api/banks/v1')
            .then(response => response.json())
            .then(data => setBanks(data))
            .catch(error => console.error('Ocorreu um erro ao buscar os bancos:', error));
        setDadosBancarios(`Banco: ${selectedBank}-${bankNumber}/Agencia: ${agency}/Conta: ${conta}`);
    }, [dadosBancarios]);
    const handleBankChange = (event) => {
        const selectedBankName = event.target.value;
        setSelectedBank(selectedBankName);
        const selectedBankData = banks.find(bank => bank.name === selectedBankName);

        if (selectedBankData) {
            setBankNumber(selectedBankData.code);
        }
        else if (selectedBankName === 'BNK Digital') {
            setBankNumber(547);
        } else {
            setBankNumber('');
        }
    };
    const keywordsToPrioritize = ['caixa eco', 'itaú uni', 'bco do brasil', 'bradesco s', 'santander', 'original', 'banco inter', 'bdigital'];

    const sortedBanks = [...banks].sort((a, b) => {
        const bankNameA = a.name ? a.name.toLowerCase() : '';
        const bankNameB = b.name ? b.name.toLowerCase() : '';

        const priorityA = getPriority(bankNameA);
        const priorityB = getPriority(bankNameB);

        if (priorityA !== priorityB) {
            return priorityA - priorityB; // Coloque o banco com prioridade maior primeiro
        } else {
            return bankNameA.localeCompare(bankNameB); // Ordem alfabética padrão
        }
    });
    const addAvalista = () => {
        setAvalistas([...avalistas, { aNome: '', aCpf: '', aEmail: '', aCep: '', aRg: '', aRua: '', aCidade: '', aEstado: '', aNumero: '', aComplemento: '', aProfissao: '', aNacionalidade: 'Brasileiro(a)', aEstadoCivil: 'solteiro(a)', aDataNascimento: '' }]);
    };
    function getPriority(name) {
        for (let i = 0; i < keywordsToPrioritize.length; i++) {
            if (name.includes(keywordsToPrioritize[i])) {
                return i; // Retorna a posição da primeira correspondência encontrada
            }
        }
        return keywordsToPrioritize.length; // Se não houver correspondências, use a prioridade máxima
    }

    const sendInfos = () => {
       /* const dados = {
            userRef: user.data.email,
            razaoSocial: dRazao,
            cnpj: dCnpj,
            email: dEmail,
            cpf: dCpf,
            representante,
            cargo,
            banco: selectedBank,
            bankNumber,
            conta,
            agency,
            cep: dCep,
            rua: dRua,
            numero: dNumero,
            bairro: dBairro,
            cidade: dCidade,
            estado: dEstado,
            complemento: dComplemento,
            fielDepositario: {
                nome: fName,
                nacionalidade: fNacionalidade,
                ocupacao: fOcupacao,
                cep: fCep,
                rua: fRua,
                numero: fNumero,
                complemento: fComplemento,
                cidade: fCidade,
                estado: fEstado,
                cpf: fCpf,
                rg: fRg,
                email: fEmail

            },
            devedorSolidario: devedorInfo,
            avalistas,
            meioPagamento,
        }*/
        const dados = {
            userRef: user.data.email,
            razaoSocial: dRazao,
            cnpj: dCnpj,
            email: dEmail,
            cpf: dCpf,
            representante: representante,
            cargo: cargo,
            banco: selectedBank,
            bankNumber: bankNumber,
            conta:conta,
            agency: agency,
            cep: dCep,
            rua: dRua,
            numero: dNumero,
            bairro: dBairro,
            cidade: dCidade,
            estado: dEstado,
            complemento: dComplemento,
            fielDepositario: {
                nome: fName,
                ocupacao: fOcupacao,
                fCep: fCep,
                fRua: fRua,
               fNumero: fNumero,
                fComplemento: fComplemento,
                fCidade: fCidade,
                fEstado: fEstado,
                fCpf: fCpf,
                fRg: fRg,
                fEmail: fEmail
            },
            devedorSolidario: devedorInfo,
            avalistas: avalistas,
            meioPagamento,
        }

        // Envia os dados atualizados para a API
        console.log(dados, 'editedData');
       // const url = 'https://c2easybe-production.up.railway.app';
        const url = process.env.REACT_APP_BASE_URL;
        const localhost = 'http://localhost:6024';
        axios.put(`${url}/emissor/${emissorData._id}`, dados)
            .then((response) => {
                console.log(response.data);
                localStorage.setItem('emissor', JSON.stringify(dados));
                window.alert('Dados atualizados com sucesso!');
                onClose();
            })
            .catch((error) => {
                // Lógica para lidar com erros na requisição
                console.error("Erro ao atualizar dados do investidor:", error);
            });
    };
    const handleAvalistaChange = (e, index, field) => {
        const newAvalistas = [...avalistas];
        newAvalistas[index][field] = e.target.value;
        setAvalistas(newAvalistas);
    };


    const removeAvalista = (indexToRemove) => {
        const updatedAvalistas = [...avalistas];
        updatedAvalistas.splice(indexToRemove, 1); // Remove o avalista pelo índice
        setAvalistas(updatedAvalistas);
    };


    const handleSearch2 = async () => {
        try {
            setIsLoading2(true);
            const response = await axios.get(`https://viacep.com.br/ws/${dCep}/json/`);
            setDAdress(response.data);
            setDBairro(response.data.bairro);
            setDCidade(response.data.localidade);
            setDEstado(response.data.uf);
            setDRua(response.data.logradouro);
        } catch (error) {
            console.error('Error fetching address:', error);
        } finally {
            setIsLoading2(false);
        }
    };
    const toggleFielVisibility = () => {
        setFiel(!fiel);
    };
    const handleSearch = async (cep) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
            const data = response.data;

            setFRua(data.logradouro || "");
            setFNumero("");
            setFComplemento("");
            setFCidade(data.localidade || "");
            setFEstado(data.uf || "");
        } catch (error) {
            console.error('Erro ao buscar endereço:', error);
        } finally {
            setIsLoading(false);
        }
    };
    const handleCepChange = (e) => {
        const newCep = e.target.value.replace(/\D/g, "");
        setFCep(newCep);

        if (newCep.length === 8) {
            handleSearch(newCep);
        }
    };

    const handleSearchAva = async (cep, index) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
            const data = response.data;

            const newAvalistas = [...avalistas];
            const currentAvalista = newAvalistas[index];

            currentAvalista.aRua = data.logradouro || "";
            currentAvalista.aNumero = "";
            currentAvalista.aBairro = data.bairro || "";
            currentAvalista.aCidade = data.localidade || "";
            currentAvalista.aEstado = data.uf || "";

            setAvalistas(newAvalistas);
        } catch (error) {
            console.error('Erro ao buscar endereço:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCepChangeAva = (e, index, field) => {
        const newCep = e.target.value.replace(/\D/g, "");
        const newAvalistas = [...avalistas];
        newAvalistas[index][field] = e.target.value;
        setAvalistas(newAvalistas);

        if (newCep.length === 8) {
            handleSearchAva(newCep, index);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Emissor Modal"
        >
            <div className="modalBody">


                <div className="formDoc1">
                    <div className="divTitleDoc">
                        <h1 className="h1Doc">EMISSOR</h1>
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            Razão Social do Emissor:
                        </label>
                        <input
                            type="text"
                            placeholder="Razão Social"
                            className={`inputsDoc`}
                            value={dRazao}
                            onChange={(e) => { setDRazao(e.target.value) }}
                            maxLength={120} />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            CNPJ:
                        </label>
                        <input
                            type="text"
                            placeholder="CNPJ"
                            className={`inputsDoc`}
                            value={formatCnpj(dCnpj)}
                            onChange={(e) => { setDCnpj(e.target.value) }}
                            maxLength={18} />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            CEP:
                        </label>
                        <ReactInputMask
                            mask="99999-999"
                            maskChar=""
                            type="text"
                            placeholder="CEP"
                            className={`inputsDoc`}
                            value={dCep}
                            onChange={(e) => { setDCep(e.target.value) }}
                            onBlur={handleSearch2}
                        />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            Número:
                        </label>
                        <input
                            type="text"
                            placeholder="Número"
                            className={`inputsDoc`}
                            value={dNumero}
                            onChange={(e) => { setDNumero(e.target.value) }}
                        />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            Complemento:
                        </label>
                        <input
                            type="text"
                            placeholder="Complemento"
                            className={`inputsDoc`}
                            value={dComplemento}
                            onChange={(e) => { setDComplemento(e.target.value) }}
                        />
                    </div>
                    {isLoading2 ? (
                        <p className="loading">Carregando...</p>
                    ) : (
                        <div className="divInputsDoc2">
                            <div className="divInputsDoc">
                                <label htmlFor="" className="labelInputsDoc">
                                    Rua:
                                </label>
                                <input
                                    type="text"
                                    placeholder="Rua"
                                    className={`inputsDoc`}
                                    value={dRua}
                                    onChange={(e) => { setDRua(e.target.value) }}
                                />
                            </div>

                            <div className="divInputsDoc">
                                <label htmlFor="" className="labelInputsDoc">
                                    Bairro:
                                </label>
                                <input
                                    type="text"
                                    placeholder="Complemento"
                                    className={`inputsDoc`}
                                    value={dBairro}
                                    onChange={(e) => { setDBairro(e.target.value) }}
                                />
                            </div>

                            <div className="divInputsDoc">
                                <label htmlFor="" className="labelInputsDoc">
                                    Cidade:
                                </label>
                                <input
                                    placeholder="Cidade"
                                    type="text"
                                    className={`inputsDoc`}
                                    value={dCidade}
                                    onChange={(e) => { setDCidade(e.target.value) }}
                                />
                            </div>
                            <div className="divInputsDoc">
                                <label htmlFor="" className="labelInputsDoc">
                                    Estado:
                                </label>
                                <input
                                    type="text"
                                    placeholder="Estado"
                                    className={`inputsDoc`}
                                    value={dEstado}
                                    onChange={(e) => { setDEstado(e.target.value) }}
                                />
                            </div>
                        </div>)
                    }
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            e-mail:
                        </label>
                        <input
                            type="text"
                            placeholder="Email"
                            className={`inputsDoc`}
                            value={dEmail}
                            onChange={(e) => { setDEmail(e.target.value) }}
                            maxLength={40} />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            Representante Legal
                        </label>
                        <input
                            type="text"
                            placeholder="Representante Legal"
                            className={`inputsDoc`}
                            value={representante}
                            onChange={(e) => { setRepresentante(e.target.value) }}
                            maxLength={40} />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            CPF
                        </label>
                        <input
                            type="text"
                            placeholder="CPF"
                            className={`inputsDoc`}
                            value={formatCpf(dCpf)}
                            onChange={(e) => { setDCpf(e.target.value) }}
                            maxLength={14} />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            Cargo
                        </label>
                        <input
                            type="text"
                            placeholder="Cargo"
                            className={`inputsDoc`}
                            value={cargo}
                            onChange={(e) => { setCargo(e.target.value) }}
                            maxLength={40} />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            Dados Bancários
                        </label>
                        <select value={selectedBank} onChange={handleBankChange} className="selectDoc">
                            <option value="" className="optionInputsDoc">Selecione um banco</option>
                            <option value="BNK Digital" className="optionInputsDoc">BNK Digital</option>
                            {sortedBanks.map((bank, index) => (
                                <option key={index} value={bank.name} className="optionInputsDoc">
                                    {bank.name}
                                </option>
                            ))}
                        </select>

                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">Numero do Banco</label>
                        <input
                            type='text'
                            name="Número do banco"
                            placeholder="Selecione um banco"
                            className={`inputsDoc`}
                            value={bankNumber}
                            onChange={(e) => { setBankNumber(e.target.value) }}
                            maxLength={20}
                        />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            Agência:
                        </label>
                        <input
                            placeholder="12345"
                            type="text"
                            className={`inputsDoc`}
                            value={agency}
                            onChange={(e) => { setAgency(e.target.value) }}
                            maxLength={8} />
                    </div>
                    <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                            Conta:
                        </label>
                        <input
                            placeholder="12345"
                            type="text"
                            className={`inputsDoc`}
                            value={conta}
                            onChange={(e) => { setConta(e.target.value) }}
                            maxLength={10} />
                    </div>
                    <div className="divInputsDoc">
                        <label className="labelInputsDoc">Meio de Pagamento</label>
                        <select name="meioDePagamento" value={meioPagamento} onChange={(e) => { setMeioPagamento(e.target.value) }} className="selectDoc">
                            <option value={`[X] Transferência Eletrônica Disponível (TED)`}>Transferência Eletrônica Disponível (TED)</option>
                            <option value={`[X] Dação em Pagamento`}>Dação em Pagamento</option>
                        </select>
                    </div>

                </div>


                <div className='formDoc1'>
                    {avalistas.map((avalista, index) => (
                        <div key={index} className="mainAvalista">
                            <h1 className="h1DocAva">Avalista {index + 1}</h1>
                            <div className="divInputsDocAva">
                                <label htmlFor="" className="labelInputsDoc" style={{ marginTop: '2vh' }}>
                                    Nome:
                                </label>
                                <input
                                    type="text"
                                    className={`inputsDocAva`}
                                    value={avalista.aNome}
                                    onChange={(e) => handleAvalistaChange(e, index, 'aNome')}
                                    maxLength={40}
                                />
                            </div>
                            <div className="divInputsDocAva">
                                <label htmlFor="" className="labelInputsDoc" style={{ marginTop: '2vh' }}>
                                    CPF/CNPJ:
                                </label>
                                <input
                                    type="text"
                                    className={`inputsDocAva`}
                                    value={formatDocument(avalista.aCpf)}
                                    onChange={(e) => handleAvalistaChange(e, index, 'aCpf')}
                                    maxLength={19}
                                />
                            </div>
                            <div className="divInputsDocAva">
                                <label htmlFor="" className="labelInputsDoc" style={{ marginTop: '2vh' }}>
                                    RG:
                                </label>
                                <input
                                    type="text"
                                    className={`inputsDocAva`}
                                    value={formatRg(avalista.aRg)}
                                    onChange={(e) => handleAvalistaChange(e, index, 'aRg')}
                                    maxLength={16}
                                />

                            </div>
                            <div className="divInputsDocAva" style={{ marginTop: '2vh' }}>
                                <label htmlFor="" className="labelInputsDoc">
                                    Email:
                                </label>
                                <input
                                    type="text"
                                    className={`inputsDocAva`}
                                    value={avalista.aEmail}
                                    onChange={(e) => handleAvalistaChange(e, index, 'aEmail')}
                                />
                            </div>
                            <div className="divInputsDocAva" style={{ marginTop: '2vh' }}>
                                <label htmlFor="nacionalidade" className="labelInputsDoc">
                                    Nacionalidade:
                                </label>
                                <select
                                    className="inputsDocAva"
                                    value={avalista.aNacionalidade ??"Brasileiro(a)"}
                                    onChange={(e) => handleAvalistaChange(e, index, 'aNacionalidade')}
                                    id="nacionalidade"
                                >
                                    <option value="Brasileiro(a)">Brasileiro(a)</option>
                                    <option value="Americano(a)">Americano(a)</option>
                                    <option value="Canadense">Canadense</option>
                                    <option value="Frances(a)">Frances(a)</option>
                                    <option value="Alemão(a)">Alemão(a)</option>
                                    <option value="Italiano(a)">Italiano(a)</option>
                                    <option value="Japones(a)">Japones(a)</option>
                                    <option value="Chines(a)">Chines(a)</option>
                                    <option value="Indiano(a)">Indiano(a)</option>
                                    <option value="Russo(a)">Russo(a)</option>
                                    <option value="Mexicano(a)">Mexicano(a)</option>
                                    <option value="Outra">Outra</option>
                                </select>
                            </div>


                            <div className="divInputsDocAva" style={{ marginTop: '2vh' }}>
                                <label htmlFor="" className="labelInputsDoc">
                                    Estado Civil:
                                </label>
                                <select
                                    className={`selectDoc`}

                                    value={avalista.aEstadoCivil??"solteiro(a)"}
                                    onChange={(e) => handleAvalistaChange(e, index, 'aEstadoCivil')}
                                >
                                    <option value="solteiro(a)">Solteiro(a)</option>
                                    <option value="casado(a)">Casado(a)</option>
                                    <option value="divorciado(a)">Divorciado(a)</option>
                                    <option value="viuvo">Viúvo</option>
                                    {/* Adicione mais opções conforme necessário */}
                                </select>
                            </div>

                            <div className="divInputsDocAva" style={{ marginTop: '2vh' }}>
                                <label htmlFor="" className="labelInputsDoc">
                                    Profissão:
                                </label>
                                <input
                                    type="text"
                                    className={`inputsDocAva`}
                                    value={avalista.aProfissao}
                                    onChange={(e) => handleAvalistaChange(e, index, 'aProfissao')}
                                />
                            </div>
                            <div className="divInputsDocAva" style={{ marginTop: '2vh' }}>
                                <label htmlFor="" className="labelInputsDoc">
                                    Data de Nascimento:
                                </label>
                                <input
                                    type="date"
                                    className={`inputsDocAva`}
                                    value={avalista.aDataNascimento}
                                    onChange={(e) => handleAvalistaChange(e, index, 'aDataNascimento')}
                                    maxLength={40}
                                />
                            </div>
                            <div className="divInputsDocAva" style={{ marginTop: '2vh' }}>
                                <label htmlFor="" className="labelInputsDoc">
                                    CEP:
                                </label>
                                <input
                                    type="text"
                                    className={`inputsDocAva`}
                                    value={avalista.aCep}
                                    onChange={(e) => handleCepChangeAva(e, index, 'aCep')}
                                />
                            </div>
                            <div className="divInputsDocAva" style={{ marginTop: '2vh' }}>
                                <label htmlFor="" className="labelInputsDoc">
                                    Rua:
                                </label>
                                <input
                                    type="text"
                                    className={`inputsDocAva`}
                                    value={avalista.aRua}
                                    onChange={(e) => handleAvalistaChange(e, index, 'aRua')}
                                />
                            </div>
                            <div className="divInputsDocAva" style={{ marginTop: '2vh' }}>
                                <label htmlFor="" className="labelInputsDoc">
                                    Número:
                                </label>
                                <input
                                    type="text"
                                    className={`inputsDocAva`}
                                    value={avalista.aNumero}
                                    onChange={(e) => handleAvalistaChange(e, index, 'aNumero')}
                                    maxLength={14}
                                />
                            </div>
                            <div className="divInputsDocAva" style={{ marginTop: '2vh' }}>
                                <label htmlFor="" className="labelInputsDoc">
                                    Bairro:
                                </label>
                                <input
                                    type="text"
                                    className={`inputsDocAva`}
                                    value={avalista.aBairro}
                                    onChange={(e) => handleAvalistaChange(e, index, 'aBairro')}
                                />
                            </div>
                            <div className="divInputsDocAva" style={{ marginTop: '2vh' }}>
                                <label htmlFor="" className="labelInputsDoc">
                                    Complemento:
                                </label>
                                <input
                                    type="text"
                                    className={`inputsDocAva`}
                                    value={avalista.aComplemento}
                                    onChange={(e) => handleAvalistaChange(e, index, 'aComplemento')}
                                />
                            </div>
                            <div className="divInputsDocAva" style={{ marginTop: '2vh' }}>
                                <label htmlFor="" className="labelInputsDoc">
                                    Cidade:
                                </label>
                                <input
                                    type="text"
                                    className={`inputsDocAva`}
                                    value={avalista.aCidade}
                                    onChange={(e) => handleAvalistaChange(e, index, 'aCidade')}
                                />
                            </div>
                            <div className="divInputsDocAva" style={{ marginTop: '2vh' }}>
                                <label htmlFor="" className="labelInputsDoc">
                                    Estado:
                                </label>
                                <input
                                    type="text"
                                    className={`inputsDocAva`}
                                    value={avalista.aEstado}
                                    onChange={(e) => handleAvalistaChange(e, index, 'aEstado')}
                                />
                            </div>
                            <div className="addButton2" onClick={() => removeAvalista(0)}>Remover Avalista</div>
                        </div>
                    ))}
                    <div className="addButton" onClick={addAvalista}>
                        + Adicionar Avalista
                    </div>
                    <div className="formDoc1">
                        <div className="divTitleDoc">
                            <h1 className="h1Doc">FIEL DEPOSITÁRIO</h1>
                        </div>
                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                Nome:
                            </label>
                            <input
                                type="text"
                                className={`inputsDoc`}
                                value={fName}
                                onChange={(e) => { setFName(e.target.value) }}
                                maxLength={40} />
                        </div>
                       {/* <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                Nacionalidade:
                            </label>
                            <input
                                type="text"
                                className={`inputsDoc`}
                                value={fNacionalidade}
                                onChange={(e) => { setFNacionalidade(e.target.value) }}
                                maxLength={40} />
                    </div>*/}
                        
                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                Ocupação:
                            </label>
                            <input
                                type="text"
                                className={`inputsDoc`}
                                value={fOcupacao}
                                onChange={(e) => { setFOcupacao(e.target.value) }}
                                maxLength={40} />
                        </div>

                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                CEP:
                            </label>
                            <ReactInputMask
                                mask="99999-999"
                                maskChar=""
                                type="text"
                                className={`inputsDoc`}
                                value={fCep}
                                onChange={handleCepChange}
                            />
                        </div>
                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                Rua:
                            </label>
                            <input
                                type="text"
                                className={`inputsDoc`}
                                value={fRua}
                                onChange={(e) => setFRua(e.target.value)}
                                maxLength={40}
                            />
                        </div>
                        <div className="divInputsDoc">
                                <label htmlFor="" className="labelInputsDoc">
                                    Número:
                                </label>
                                <input
                                    type="text"
                                    className={`inputsDoc`}
                                    value={fNumero}
                                    onChange={(e) => setFNumero(e.target.value)}
                                    maxLength={40}
                                 />
                        </div>
                        <div className="divInputsDoc">
                                <label htmlFor="" className="labelInputsDoc">
                                    Complemento:
                                </label>
                                <input
                                    type="text"
                                    className={`inputsDoc`}
                                    value={fComplemento}
                                    onChange={(e) => setFComplemento(e.target.value)}
                                    maxLength={40}
                                />
                        </div>
                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                Cidade:
                            </label>
                            <input
                                type="text"
                                className={`inputsDoc`}
                                value={fCidade}
                                onChange={(e) => setFCidade(e.target.value)}
                                maxLength={40}
                            />
                        </div>
                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                Estado:
                            </label>
                            <input
                                type="text"
                                className={`inputsDoc`}
                                value={fEstado}
                                onChange={(e) => setFEstado(e.target.value)}
                                maxLength={40}
                            />
                        </div>
                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                CPF:
                            </label>
                            <input
                                type="text"
                                className={`inputsDoc`}
                                value={formatCpf(fCpf)}
                                onChange={(e) => { setFCpf(e.target.value) }}
                                maxLength={14} />
                        </div>
                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                RG:
                            </label>
                            <input
                                type="text"
                                className={`inputsDoc`}
                                value={fRg}
                                onChange={(e) => { setFRg(e.target.value) }}
                                maxLength={40} />
                        </div>
                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                e-mail:
                            </label>
                            <input
                                type="text"
                                className={`inputsDoc`}
                                value={fEmail}
                                onChange={(e) => { setFEmail(e.target.value) }}
                                maxLength={40} />
                        </div>
                    </div>


                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>

                        <button className="button" onClick={sendInfos}>Atualizar Emissor</button>
                        <button onClick={onClose} className="button">Cancelar</button>
                    </div>
                    {erroValidacao && (
                        <div className="mensagemErro">
                            Erro de validação: {erroValidacao}
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default EmissorModal;