import React, { useEffect, useState } from "react";
import '../styles/Emissors.css';
import axios from "axios";
import { useDispatch } from "react-redux";
import { setEmissor } from "../redux/actions";
import { useNavigate } from "react-router-dom";
import { FaPlus, FaTrashCan } from "react-icons/fa6";
import ConvertApi from 'convertapi-js'
import { GrDocumentUpdate } from "react-icons/gr";
import EmissorModal from "../utils/Modals/UpdateEmissor";
import { IoIosCloudUpload } from "react-icons/io";
import { BsArrowLeft, BsEye } from "react-icons/bs";
import { formatDocument } from "../utils/Tools";
import DetailsEmissor from "./EmissorModal";
import { FaEdit } from "react-icons/fa";
import { sanitizeToNumbers, removeSpecialChars, apenasNumeros, customSearch } from "../utils/Tools";
let convertApi = ConvertApi.auth('secret_69SIAEgJFNBWhRNC');
let params = convertApi.createParams()

const Emissors = () => {
    const [emissores, setEmissores] = useState([]);
    const [selectedItemIndex, setSelectedItemIndex] = useState(null); // Estado para controlar o item selecionado
    const [selectedEmissor, setSelectedEmissor] = useState(null); // Estado para armazenar os detalhes do emissor selecionado
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [img, setImg] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deleteError, setDeleteError] = useState(false);
    const [documentFields, setDocumentFields] = useState([]);
    const [modalEmissor, setModalEmissor] = useState(false);
    const [modalEmissorIsOpen, setModalEmissorIsOpen] = useState(false);
    const [detailsEmissor, setDetailsEmissor] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const totalPages = Math.ceil(emissores.length / itemsPerPage);
    const goToNextPage = () => setCurrentPage((page) => Math.min(page + 1, totalPages));
    const goToPreviousPage = () => setCurrentPage((page) => Math.max(page - 1, 1));
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = emissores.slice(startIndex, endIndex);

    const [search, setSearch] = useState("");
    const [searchedEmissores, setSearchedEmissores] = useState([]);
    const doSearch = (e) => {
        const value = e.target.value;
        setSearch(value);
        const searchMatching = (_emissores, _search) => {
            if (_search == '') return false;
            try {
                const _numberSearch = sanitizeToNumbers(_search?.trim())?.toString() ?? "";
                const _searchEscaped = removeSpecialChars(_search);

                /* Cnpj */
                const cnpj = apenasNumeros(_emissores?.cnpj).toString();
                const hasCnpj = customSearch(cnpj, _numberSearch);
                if (hasCnpj > -1) {
                    return true;
                }
                /* Razao Social */
                const razaoSocial = _emissores?.razaoSocial?.trim();
                const hasRazaoSocial = customSearch(razaoSocial, _searchEscaped);
                if (hasRazaoSocial > -1) {
                    return true;
                }
                /* Representante */
                const representante = _emissores?.representante?.trim();
                const hasRepresentante = customSearch(representante, _searchEscaped);
                if (hasRepresentante > -1) {
                    return true;
                }
                /* Email */
                const email = _emissores?.email?.trim()??"";
                const hasEmail = customSearch(email,_searchEscaped);
                if(hasEmail>-1){
                    return true;
                }

            } catch (error) {
                console.error(error);
                return false;
            }
            return false;
        }
        let _emissores = emissores.filter((inv) => searchMatching(inv, value));
        setSearchedEmissores(_emissores.slice(0, 5));
    }
    const cleanSearch = () => {
        setSearchedEmissores([]);
        setSearch('');
    }
    const url = process.env.REACT_APP_BASE_URL

    const toggleModal = () => {
        setModalEmissor(!modalEmissor);
    }
    const openEmissorModal = (index) => {

        setSelectedEmissor(index); //atualiza com emissor selecionado
        setModalEmissorIsOpen(true);
    };

    const closeInvestorModal = () => {
        setModalEmissorIsOpen(false);
    };

    const user = JSON.parse(localStorage.getItem('user'));
    const userRef = `${user.data.email}`;

    const headers = {
    }
    useEffect(() => {
        axios.get(`${url}/emissor?userRef=${userRef}`, headers)
            .then((response) => {
                setEmissores(response.data);
            })
            .catch((error) => {
                console.error('Erro ao fazer a solicitação:', error);
            });
    }, [userRef]);

    const handleSocios = async (e) => {
        setLoading(true);
        const selectedFile = e.target.files[0];
        const params = convertApi.createParams();
        params.add('File', selectedFile);
        try {
            const result = await convertApi.convert('pdf', 'jpg', params);
            const imageUrls = result.dto.Files.map((file) => file.Url);
            setImg((prevImg) => [...prevImg, ...imageUrls]);
            localStorage.setItem('socios', JSON.stringify([...img, ...imageUrls]));
            setLoading(false);
        } catch (error) {
            console.error('Erro ao converter arquivo:', error);
            setLoading(false);
        }
    }

    const removeLastDocumentField = () => {
        const updatedFields = [...documentFields];
        updatedFields.pop(); // Remove o último elemento do array
        setDocumentFields(updatedFields);
    };


    useEffect(() => {

        if (selectedItemIndex !== null) {
            const selectedEmissorData = emissores[selectedItemIndex];
            setSelectedEmissor(selectedEmissorData);
        } else {
            setSelectedEmissor(null);
        }
    }, [selectedItemIndex, emissores]);


    const handleDeleteInfos = (index) => {
        setSelectedItemIndex(index);
        setShowConfirmation(true);
    }

    const toggleDetails = (index) => {
        setSelectedEmissor(index);
        setDetailsEmissor(true);
    }

    const handleDelete = (index) => {
        if (index !== null) {
            const emissorId = emissores[index]._id;
            axios.delete(`${url}/emissor?id=${emissorId}`, headers)
                .then((response) => {
                    if (response.status === 204) {
                        setDeleteSuccess(true);
                        setTimeout(() => {
                            setDeleteSuccess(false);
                        }, 3000);
                        setSelectedItemIndex(null); // Limpa a seleção após a exclusão
                        setShowConfirmation(false);

                        // Após a exclusão bem-sucedida, obtenha a lista atualizada
                        axios.get(`${url}/emissor?userRef=${userRef}`, headers)
                            .then((response) => {
                                const updatedEmissores = response.data;
                                setEmissores(updatedEmissores.length > 0 ? updatedEmissores : []); // Verifica se a lista ficará vazia e ajusta emissores
                            })
                            .catch((error) => {
                                console.error('Erro ao fazer a solicitação:', error);
                            });
                    } else {
                        setDeleteError(true);
                        setTimeout(() => {
                            setDeleteError(false);
                        }, 3000);
                    }
                })
                .catch((error) => {
                    console.error('Erro ao fazer a solicitação:', error);
                });
        }
    };


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleUseEmissor = () => {
        localStorage.setItem('emissorPreenchido', true);
        localStorage.setItem('emissor', JSON.stringify(selectedEmissor));
        dispatch(setEmissor(selectedEmissor));
        window.alert('Emissor Selecionado!');
        navigate('/docgen')
    }
    return (
        <div className="divTitleInvestidor">
            <form className="searchForm" >
                <input type="text" placeholder="Busca" value={search} name="search" onChange={doSearch} className="inputSearch" />
                <button type="reset" aria-hidden="false" onClick={cleanSearch} className="btnClean" >X</button>
            </form>
            {searchedEmissores.length > 0 ? (
                <table className="investidorTable">
                    <thead>
                        <tr>
                            <th>Razão Social</th>
                            <th>CNPJ</th>
                            <th>Representante</th>
                            <th>Email</th>
                            <th>Cargo</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {searchedEmissores.map((emissor, index) => (
                            <tr key={index}>

                                <td>
                                    {emissor.razaoSocial.length > 20
                                        ? `${emissor.razaoSocial.substring(0, 20)}...`
                                        : emissor.razaoSocial}
                                </td>
                                <td>{emissor.cnpj}</td>
                                <td>
                                    {emissor.representante.length > 20
                                        ? `${emissor.representante.substring(0, 20)}...`
                                        : emissor.representante}
                                </td>
                                <td>{emissor.email}</td>
                                <td>{emissor.cargo}</td>
                                <td>
                                    <div className="tableIcons">
                                        <button onClick={() => toggleDetails(emissor)} className="updateButton">
                                            <BsEye color="#1a2f67" size={20} />
                                        </button>
                                        <p className="confirmationMessage">
                                            <button className="deleteButton" onClick={() => handleDeleteInfos(index)}> <FaTrashCan size={20} /></button>
                                        </p>
                                        <button className="updateButton" onClick={() => openEmissorModal(emissor)}><FaEdit size={20} /></button>

                                    </div>
                                </td>
                            </tr>
                        ))}
                        {showConfirmation &&
                            <div className="confirmationMessage">Confirmar exclusão de {emissores[selectedItemIndex]?.razaoSocial}?
                                <button className="confirmationButton" onClick={() => handleDelete(selectedItemIndex)}>Sim</button>
                                <button className="confirmationButton" onClick={() => setShowConfirmation(false)}>Não</button>
                            </div>
                        }
                    </tbody>
                </table>

            ) : (search.length? 
                (<div className="detailsDiv2">
                    <h3 className="confirmationMessage1">Nenhum emissor encontrado</h3>
                </div>)
                :null)
            }
            <Displayer disabled={search.length == 0}>
                {
                    emissores.length > 0 ? (
                        <table className="investidorTable">
                            <thead>
                                <tr>
                                    <th>Razão Social</th>
                                    <th>CNPJ</th>
                                    <th>Representante</th>
                                    <th>Email</th>
                                    <th>Cargo</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((emissor, index) => (
                                    <tr key={index}>

                                        <td>
                                            {emissor.razaoSocial.length > 20
                                                ? `${emissor.razaoSocial.substring(0, 20)}...`
                                                : emissor.razaoSocial}
                                        </td>
                                        <td>{emissor.cnpj}</td>
                                        <td>
                                            {emissor.representante.length > 20
                                                ? `${emissor.representante.substring(0, 20)}...`
                                                : emissor.representante}
                                        </td>
                                        <td>{emissor.email}</td>
                                        <td>{emissor.cargo}</td>
                                        <td>
                                            <div className="tableIcons">
                                                <button onClick={() => toggleDetails(emissor)} className="updateButton">
                                                    <BsEye color="#1a2f67" size={20} />
                                                </button>
                                                <p className="confirmationMessage">
                                                    <button className="deleteButton" onClick={() => handleDeleteInfos(index)}> <FaTrashCan size={20} /></button>
                                                </p>
                                                <button className="updateButton" onClick={() => openEmissorModal(emissor)}><FaEdit size={20} /></button>

                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                {showConfirmation &&
                                    <div className="confirmationMessage">Confirmar exclusão de {emissores[selectedItemIndex]?.razaoSocial}?
                                        <button className="confirmationButton" onClick={() => handleDelete(selectedItemIndex)}>Sim</button>
                                        <button className="confirmationButton" onClick={() => setShowConfirmation(false)}>Não</button>
                                    </div>
                                }
                            </tbody>
                        </table>

                    ) : (
                        <div className="detailsDiv2">
                            <h3 className="confirmationMessage1">Nenhum emissor encontrado</h3>
                        </div>
                    )
                }
                <div className="pagination">
                    <button onClick={goToPreviousPage} disabled={currentPage === 1}>Anterior</button>
                    <span>Página {currentPage} de {totalPages}</span>
                    <button onClick={goToNextPage} disabled={currentPage === totalPages}>Próxima</button>
                </div>
            </Displayer>
            {deleteSuccess && <p className="successMessage">Emissor Excluido com sucesso!</p>}
            {deleteError && <p className="errorMessage">Falha ao Excluir emissor.</p>}

            {detailsEmissor && (
                <DetailsEmissor
                    selectedEmissor={selectedEmissor}
                    handleSave={handleUseEmissor}
                    onClose={() => { setDetailsEmissor(false) }}
                />
            )}
            {modalEmissorIsOpen && selectedEmissor && (
                <EmissorModal
                    isOpen={modalEmissorIsOpen}
                    onClose={closeInvestorModal}
                    emissorData={selectedEmissor}
                />
            )}
        </div >
    )
}

const Displayer = ({ children, disabled = true }) => (disabled) ? children : null;

export default Emissors;
